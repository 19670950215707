import {Component, OnInit} from '@angular/core';
import * as Highcharts from 'highcharts';
import {LanguageService} from './services/language.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'iona-app',
    templateUrl: './app.component.html',
})

export class AppComponent implements OnInit {
    constructor(private translate: TranslateService,
                private languageService: LanguageService) {
        this.translate.onLangChange.subscribe(() => {
            this.setHighchartsLanguage();
        });
    }

    setHighchartsLanguage() {
        this.translate.get('highcharts').subscribe(translations => {
            const getShortenedNames = (arr: string[]): string[] => arr.map(name => name.slice(0, 3));

            Highcharts.setOptions({
                time: {
                    timezoneOffset: new Date().getTimezoneOffset()
                },
                lang: {
                    thousandsSep: '.',
                    decimalPoint: ',',
                    loading: translations.loading,
                    months: Object.values(translations.months),
                    shortMonths: getShortenedNames(Object.values(translations.months)),
                    weekdays: Object.values(translations.weekdays),
                    shortWeekdays: getShortenedNames(Object.values(translations.weekdays))
                }
            });
        });
    }

    ngOnInit(): void {
        this.languageService.initTranslate();
        this.setHighchartsLanguage();
    }
}
