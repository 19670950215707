import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {LanguageService} from '../../services/language.service';

@Pipe({
    name: 'nameOfDay'
})
export class NameOfDayPipe implements PipeTransform {

    constructor(private languageService: LanguageService) {
    }

    transform(value: Date, ...args: unknown[]): string {
        const selectedLanguage = this.languageService.selectedLanguage || 'de';

        moment.locale(selectedLanguage);

        return moment(value).format('dddd');
    }

}
