import {AfterViewInit, Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {ElectricityService} from '../../../services/electricity.service';
import {HappyHourService} from '../../../services/happy-hour.service';
import {ApplicationService} from '../../../services/application.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {Observable, of, share, Subject} from 'rxjs';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {Tariff} from '../../../classes/user';
import {UserService} from '../../../services/user.service';
import {ProfileService} from '../../../services/profile.service';
import {Router} from '@angular/router';
import {
    ComparisonChartComponent,
    SeriesLegendData
} from '../../../charts/static-comparison-chart/comparison-chart.component';
import {
    ComparisonDataProviderService,
    ComparisonMapping
} from '../../../services/data-provider/comparison-data-provider.service';
import {UserTariffService} from '../../../services/user-tariff.service';
import {switchMap} from 'rxjs/operators';
import {InitializationService} from '../../../services/initialization.service';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-comparison-details',
    templateUrl: './comparison-details.component.html',
    styleUrls: ['comparison-details.component.scss'],
    viewProviders: []
})
export class ComparisonDetailsComponent extends BasePopover implements OnInit, AfterViewInit {

    readonly comparisonTimeframe = ComparisonTimeframe;
    readonly comparisonMode = ComparisonMode;
    readonly comparisonDataMode = ComparisonDataMode;
    readonly comparisonDisplayMode = ComparisonDisplayMode;
    validMonths;

    private onDataModeChange = new Subject<ComparisonDataMode>();

    private comparisonChartComponent: ComparisonChartComponent | null = null;

    currentMode = ComparisonMode.STATIC;
    currentTimeframe = ComparisonTimeframe.MONTH;
    currentDisplayMode = ComparisonDisplayMode.CONSUMPTION;
    currentDataMode = ComparisonDataMode.REGULAR;

    currentPosition = 1;
    year = 0;

    dynamicComparisonDates: DynamicComparisonDates = {
        lhsDay: 0, lhsCalendarWeek: 0, lhsMonth: 0, lhsYear: 0,
        rhsDay: 0, rhsCalendarWeek: 0, rhsMonth: 0, rhsYear: 0
    };

    seriesLegend: SeriesLegendData[] = [];

    warningCollapsed = true;
    infoVisible = false;
    interactionDisabled = true;
    isVisionUser = true;

    isErnaUser = false;
    userHasHappyHour = false;

    currentBasePrice: Observable<string> = this.userTariffService.getActiveTariff$().pipe(
        switchMap(activeTariff => this.handleBasePriceChange(activeTariff))
    );

    constructor(protected popoverRef: PopoverRef,
                public application: ApplicationService,
                public userService: UserService,
                public userTariffService: UserTariffService,
                private analytics: TrackAnalyticsService,
                private electricityService: ElectricityService,
                private happyHour: HappyHourService,
                private router: Router,
                private profile: ProfileService,
                private comparisonDataProvider: ComparisonDataProviderService,
                private initialization: InitializationService,
                private translate: TranslateService
    ) {
        super(popoverRef);
    }


    ngOnInit() {
        this.comparisonDataProvider.resetTimeframeAndOffset();
        this.constructInitialDynamicComparisonDates();
        this.initializeModeChange();
        this.checkIsVisionUser();
        this.translate.get('screens.dashboard.months').subscribe((monthsTranslation: string[]) => {
            if (monthsTranslation && monthsTranslation.length) {
                this.validMonths = monthsTranslation;
            } else {
                this.validMonths = moment.months();
            }
        });
    }


    ngAfterViewInit(): void {
        if (this.application.isDemoMode()) {
            return;
        }
        if (this.userService.isEnviamUser()) {
            this.determineUserHasHappyHour();
        }
    }


    checkIsVisionUser(): void {
        this.initialization.getWithCache().subscribe((data) => {
            if ('product_name' in data) {
                this.isVisionUser = data.product_name.toLowerCase().includes('vision');
            }
        });
    }


    /**
     * Sets the current comparison mode
     * @param newMode
     */
    setMode(newMode: ComparisonMode) {
        if (newMode === this.currentMode) {
            return;
        }
        this.currentMode = newMode;
        this.comparisonDataProvider.resetTimeframeAndOffset();

        this.resetComponent();
        this.trackModeChangeEvent();
    }


    /**
     * Sets the current timeframe
     * @param newTimeframe
     */
    setTimeframe(newTimeframe: ComparisonTimeframe) {
        if (newTimeframe === this.currentTimeframe) {
            return;
        }
        this.currentTimeframe = newTimeframe;
        this.currentPosition = 1;
        this.constructInitialDynamicComparisonDates();
        this.resetComponent();

    }


    /**
     * Updates component value display mode
     * @param mode
     */
    setDisplayMode(mode: ComparisonDisplayMode) {
        this.currentDisplayMode = mode;
        if (mode === ComparisonDisplayMode.COST) {
            this.comparisonChartComponent.updateDisplayMode(true);
        } else {
            this.comparisonChartComponent.updateDisplayMode(false);
        }
        this.resetComponent();
    }


    /**
     * Update comparison dates by user selection
     * @param key
     * @param value
     */
    setComparisonDates(key, value) {
        this.dynamicComparisonDates[key] = parseInt(value, 10);
        this.resetComponent();
    }


    /**
     * Skip one timeframe ahead
     */
    positionForward() {
        if ((this.currentPosition > 1) && (!this.interactionDisabled)) {
            this.currentPosition--;
            this.resetComponent();
        }
    }


    /**
     * Determines if the position forward button is disabled
     */
    positionForwardDisabled(): boolean {
        return this.comparisonDataProvider.isStepForwardDisabled();
    }


    /**
     * Skips one timeframe backward
     */
    positionBack() {
        if (this.interactionDisabled) {
            return;
        }
        this.currentPosition++;
        this.resetComponent();
    }


    /**
     * Determines if the position back button is disabled
     */
    positionBackDisabled(): boolean {
        return this.comparisonDataProvider.isStepBackDisabled();
    }


    /**
     * Select position now
     */
    positionNow() {
        if (this.interactionDisabled) {
            return;
        }
        this.currentPosition = 1;
        this.comparisonDataProvider.resetTimeframeAndOffset();
        this.resetComponent();
    }


    /**
     * Reset the chart and reload data
     */
    resetComponent() {
        this.comparisonChartComponent.showLoadingState();

        this.interactionDisabled = true;
        this.comparisonChartComponent.reset();
        this.comparisonChartComponent.showLoadingState();
        this.seriesLegend = [];

        this.updateCurrentBasePrice();
        this.getDataForCurrentTimeframeAndMode();
    }


    /**
     * Proxy function to get a loopable array
     * @param start
     * @param times
     */
    loop(start: number, times: number) {
        const loop = [];
        for (let i = start; i < (start + times); i++) {
            loop.push(i);
        }
        return loop;
    }


    /**
     * Callback once the static chart has been loaded.
     * @param chart
     */
    onChartLoaded(chart: ComparisonChartComponent): void {
        this.comparisonChartComponent = chart;
        this.comparisonChartComponent.showLoadingState();
        this.getDataForCurrentTimeframeAndMode();
    }


    /**
     * Toggle waring on or off
     */
    toggleWarning(): void {
        this.warningCollapsed = !this.warningCollapsed;
    }


    /**
     * Route to user profile component
     */
    routeToProfile(): void {
        this.close(false);
        this.router.navigate(['meine-daten']);
    }


    /**
     * Set the current data mode to be used for display
     * @param mode
     */
    setDataMode(mode: ComparisonDataMode): void {
        this.onDataModeChange.next(mode);
    }


    /**
     *  Updates the legend each time a new series is added to the chart.
     * @param seriesLegendData
     */
    updateLegend(seriesLegendData: SeriesLegendData): void {
        if (this.currentMode === ComparisonMode.STATIC) {
            this.seriesLegend.push(seriesLegendData);
        }
    }


    /**
     * Construct the initial dates for the dynamic comparison mode.
     * This is used to set the initial values for the individual dropdowns.
     * @private
     */
    private constructInitialDynamicComparisonDates() {
        const rhsDate = moment();
        let lhsDate;

        this.year = rhsDate.year();

        switch (this.currentTimeframe) {
            case ComparisonTimeframe.WEEK: {
                lhsDate = moment(rhsDate).subtract(1, 'week');
                break;
            }
            case ComparisonTimeframe.MONTH: {
                lhsDate = moment(rhsDate).subtract(1, 'months');
                break;
            }
            case ComparisonTimeframe.YEAR: {
                lhsDate = moment(rhsDate).subtract(1, 'years');
                break;
            }
            default: {
                lhsDate = moment(rhsDate).subtract(1, 'days');
                break;
            }
        }

        this.dynamicComparisonDates = {
            lhsDay: lhsDate.date(),
            lhsCalendarWeek: lhsDate.week(),
            lhsMonth: lhsDate.month() + 1,
            lhsYear: lhsDate.year(),
            rhsDay: rhsDate.date(),
            rhsCalendarWeek: rhsDate.week(),
            rhsMonth: rhsDate.month() + 1,
            rhsYear: rhsDate.year()
        };
    }


    /**
     * Requests data for the current timeframe and mode from provider.
     * Displays the data in the chart.
     * @private
     */
    private getDataForCurrentTimeframeAndMode() {
        let request: Observable<ComparisonMapping[]>;
        if (this.application.isDemoMode()) {
            if (this.currentMode === ComparisonMode.STATIC) {
                request = this.comparisonDataProvider.getStaticDemoDataForOffsetTimeframe$(
                    this.currentPosition, this.currentTimeframe, this.currentDisplayMode
                );
            } else {
                request = this.comparisonDataProvider.getDynamicDemoDataForComparisonDates$(
                    this.dynamicComparisonDates, this.currentTimeframe, this.currentDisplayMode
                );
            }
        } else {
            if (this.currentMode === ComparisonMode.STATIC) {
                if (this.currentDataMode === ComparisonDataMode.HAPPY_HOUR) {
                    request = this.comparisonDataProvider.getStaticHappyHourDataForOffsetTimeframe$(
                        this.currentPosition, this.currentTimeframe, this.currentDisplayMode
                    );
                } else {
                    request = this.comparisonDataProvider.getStaticComparisonDataForOffset$(
                        this.currentPosition, this.currentTimeframe, this.currentDisplayMode
                    );
                }
            } else {
                if (this.currentDataMode === ComparisonDataMode.HAPPY_HOUR) {
                    request = this.comparisonDataProvider
                        .getDynamicHappyHourDataForComparisonDates$(
                            this.dynamicComparisonDates,
                            this.currentTimeframe,
                            this.currentDisplayMode
                        );
                } else {
                    request = this.comparisonDataProvider
                        .getDynamicComparisonDataForComparisonDates$(
                            this.dynamicComparisonDates,
                            this.currentTimeframe,
                            this.currentDisplayMode
                        );
                }
            }
        }

        const sub = request.subscribe({
            next: (data) => {
                if (data.first().series.data.length === 0 && data.last().series.data.length === 0) {
                    this.comparisonChartComponent.hideLoadingState();
                    this.comparisonChartComponent.showErrorState();
                    return;
                }

                this.comparisonChartComponent.addPlottableChartData(data);
                // re-enable interaction
                this.interactionDisabled = false;

                // check if there is any valid data present in the constructed series
                const summedConsumptions = data.map(item => {
                    return item.series.data.reduce((a, b) => a + b, 0);
                }).reduce((a, b) => a + b, 0);

                // if all data is zero, show error state
                if (summedConsumptions === 0) {
                    this.comparisonChartComponent.hideLoadingState();
                    this.comparisonChartComponent.showErrorState();
                    return;
                } else {
                    this.comparisonChartComponent.hideLoadingState();
                }
            },
            error: (error) => {
                console.error('Error:', error);
                this.comparisonChartComponent.hideLoadingState();
                this.comparisonChartComponent.showErrorState();
                this.interactionDisabled = true;
            },
            complete: () => {
                sub.unsubscribe();
            }
        });

    }


    /**
     * Request whether the user participates in the happy hour program
     */
    private determineUserHasHappyHour(): void {
        this.happyHour.userHasHappyHour().subscribe({
            next: (userHasHappyHour) => {
                console.log('here user has happy hour', userHasHappyHour);
                this.userHasHappyHour = userHasHappyHour;
            }
        });
    }


    /**
     * Initializing mode change response
     */
    private initializeModeChange(): void {
        const s = this.onDataModeChange.subscribe({
            next: (value: ComparisonDataMode) => {
                if (this.currentDataMode === value) {
                    console.log('No change in data mode, skipping');
                    return;
                }
                this.currentDataMode = value;
                this.constructInitialDynamicComparisonDates();

                this.comparisonDataProvider.resetTimeframeAndOffset();
                this.resetComponent();
            }
        });
        this.addSub(s);
    }


    /**
     * Returns if the component is currently in cost display mode
     */
    private isDisplayModeCost(): boolean {
        return this.currentDisplayMode === ComparisonDisplayMode.COST;
    }


    /**
     * Updates the current base price by retrieving the active tariff again.
     * This is necessary because the base price varys depending on the timeframe.
     * @private
     */
    private updateCurrentBasePrice(): void {
        this.currentBasePrice = this.userTariffService.getActiveTariff$().pipe(
            switchMap(tariff => this.handleBasePriceChange(tariff))
        );
    }


    /**
     * Returns the formatted base price for the current timeframe
     * @param tariff
     * @private
     */
    private handleBasePriceChange(tariff: Tariff): Observable<string> {
        let valueFormatted = tariff.basePrice;
        const currentLang = this.translate.currentLang;
        let unit = '€ / ';
        switch (this.currentTimeframe) {
            case ComparisonTimeframe.DAY:
                unit += this.translate.instant('screens.dashboard.comparison.day');
                valueFormatted = valueFormatted / 365;
                break;
            case ComparisonTimeframe.WEEK:
                unit += this.translate.instant('screens.dashboard.comparison.week');
                valueFormatted = valueFormatted / 52.1429;
                break;
            case ComparisonTimeframe.MONTH:
                unit += this.translate.instant('screens.dashboard.comparison.month');
                valueFormatted = valueFormatted / 12;
                break;
            case ComparisonTimeframe.YEAR:
                unit += this.translate.instant('screens.dashboard.comparison.year');
                break;
        }
        const valueFormattedStr = valueFormatted.toLocaleString(currentLang, {
            maximumFractionDigits: 2
        });
        return of(`${valueFormattedStr} ${unit}`);
    }


    /**
     * EventTracking
     * @private
     */
    private trackModeChangeEvent(): void {
        const modeStr =
            this.currentMode === ComparisonMode.DYNAMIC ? 'Anpassbar' : 'Statisch';
        this.analytics.trackEvent({
            action: 'screen_view',
            properties: {
                category: 'Screens',
                label: 'screen: Vergleich - ' + modeStr +
                    '; previous_screen: Vergleich - ' + modeStr
            }
        });
    }
}


export enum ComparisonTimeframe {
    DAY = 'days',
    WEEK = 'week',
    MONTH = 'months',
    YEAR = 'year'
}


enum ComparisonMode {
    STATIC = 'static',
    DYNAMIC = 'dynamic'
}


export enum ComparisonDisplayMode {
    COST = 'cost',
    CONSUMPTION = 'consumption',
    FEEDIN = 'feedin'
}


enum ComparisonDataMode {
    REGULAR = 'regular',
    HAPPY_HOUR = 'happy-hour'
}


export interface DynamicComparisonDates {
    lhsDay: number;
    lhsCalendarWeek: number;
    lhsMonth: number;
    lhsYear: number;
    rhsDay: number;
    rhsCalendarWeek: number;
    rhsMonth: number;
    rhsYear: number;
}


export interface DynamicComparisonDatesNew {
    lhs: {
        date: number;
        calendarWeek: number;
        month: number;
        year: number;
    };
    rhs: {
        date: number;
        calendarWeek: number;
        month: number;
        year: number;
    };
}
