import {Injectable} from '@angular/core';
import {ElectricityService} from '../electricity.service';
import {BehaviorSubject, Observable, of, Subscription, timer, zip} from 'rxjs';
import {DataProviderBaseService} from './data-provider-base.service';
import {catchError, map, mergeMap} from 'rxjs/operators';
import * as moment from 'moment/moment';
import {
    initialTodayData,
    SingleDayComparisonData,
    SingleHourData,
    TodayData
} from '../../shared/interfaces/today-tile-data.interfaces';
import {ViewState} from '../../shared/enums/view-state.enum';
import {
    ConsumptionResponseData
} from '../../shared/interfaces/plain-responses/electricity-response.interface';


@Injectable({
    providedIn: 'root'
})
export class TodayDataProviderService extends DataProviderBaseService {
    private readonly updateInterval = 30000;
    private updateIntervalSub: Subscription | null = null;
    private specifiedDateDataSub: Subscription | null = null;

    private readonly tileComparisonDate = moment()
        .subtract(7, 'days')
        .toDate();

    todayTileData$ = new BehaviorSubject<TodayData>(initialTodayData);

    todayDetailData$ = new BehaviorSubject<TodayData>(initialTodayData);

    todayDetailFullData$ = new BehaviorSubject<TodayData>(initialTodayData);


    constructor(
        private electricityService: ElectricityService,
    ) {
        super();
    }


    destroy() {
        super.destroy();
        this.killTileSub();
        this.killDetailSub();
    }


    /**
     * Kills the subscription for the tile data
     */
    killTileSub() {
        if (this.updateIntervalSub) {
            this.updateIntervalSub.unsubscribe();
            this.updateIntervalSub = null;
        }
    }


    /**
     * Kills the subscription for the detail data
     */
    killDetailSub() {
        if (this.specifiedDateDataSub) {
            this.specifiedDateDataSub.unsubscribe();
            this.specifiedDateDataSub = null;
        }
    }


    /**
     * Initializes the API Connection and continuous data fetching
     */
    startContinuousTileDataUpdate() {
        if (this.updateIntervalSub) {
            return;
        }
        this.todayTileData$.next({
            ...initialTodayData,
            viewState: ViewState.LOADING
        });
        this.updateIntervalSub = timer(0, this.updateInterval).pipe(
            mergeMap(() => this.requestAndAlignData$(this.tileComparisonDate)),
            mergeMap((todayData) => this.determineTrend$(todayData)),
            map((todayData) => ({
                ...todayData,
                viewState: ViewState.SUCCESS
            })),
            catchError(() =>
                of({
                    ...initialTodayData,
                    viewState: ViewState.ERROR
                })
            )
        ).subscribe({
            next: (data: TodayData) => {
                this.todayTileData$.next(data);
            }
        });
    }


    getTodayComparisonForSetComparisonDate(
        comparisonDate = this.tileComparisonDate,
    ): void {
        if (this.specifiedDateDataSub) {
            this.specifiedDateDataSub.unsubscribe();
            this.specifiedDateDataSub = null;
        }
        this.todayDetailData$.next({
            ...initialTodayData,
            viewState: ViewState.LOADING
        });
        this.todayDetailFullData$.next({
            ...initialTodayData,
            viewState: ViewState.LOADING
        });

        this.specifiedDateDataSub = zip([
            this.requestAndAlignData$(comparisonDate, false), // Limited hours
            this.requestAndAlignData$(comparisonDate, true)   // Full hours
        ]).pipe(
            map(([limitedData, fullData]) => {
                return {
                    limitedData,
                    fullData
                };
            }),
            catchError(() =>
                of({
                    limitedData: {
                        ...initialTodayData,
                        viewState: ViewState.ERROR
                    },
                    fullData: {
                        ...initialTodayData,
                        viewState: ViewState.ERROR
                    }
                })
            )
        ).subscribe({
            next: ({limitedData, fullData}) => {
                const limitedHours = new Set(limitedData.today.hours.map(hour => hour.hour));

                const filteredFullData = {
                    ...fullData,
                    today: {
                        ...fullData.today,
                        hours: fullData.today.hours.filter(hour => !limitedHours.has(hour.hour))
                    },
                    comparisonDate: {
                        ...fullData.comparisonDate,
                        hours: fullData.comparisonDate.hours.filter(hour => !limitedHours.has(hour.hour))
                    }
                };

                this.todayDetailData$.next(limitedData);
                this.todayDetailFullData$.next(filteredFullData);
            }
        });
    }


    /**
     * Aligns an API dataset for a single day by only considering the data to the current hour
     * @param dataset
     * @param includeEntireHourList
     */
    private alignRawData$(
        dataset: ConsumptionResponseData[],
        includeEntireHourList = false
    ): Observable<SingleDayComparisonData> {
        return new Observable((observer) => {
            try {
                let consumption = 0;
                let costs = 0;
                const hourData: SingleHourData[] = [];
                for (const hourValue of dataset) {
                    const hourObject: SingleHourData = {
                        costs: 0, hour: 0, consumption: 0
                    };

                    const dataPointTs = moment(hourValue.timestamp).toDate();
                    const now = new Date();

                    if (!includeEntireHourList) {
                        if (dataPointTs.getHours() > now.getHours()) {
                            continue;
                        }
                    }

                    if ('measured' in hourValue) {
                        if (dataPointTs.getHours() <= now.getHours()) {
                            consumption += hourValue.measured;
                        }
                        hourObject.hour = new Date(hourValue.timestamp).getHours();
                        hourObject.consumption = (hourValue.measured / 1000);
                    }
                    if ('cost_measured' in hourValue) {
                        if (dataPointTs.getHours() <= now.getHours()) {
                            costs += hourValue.cost_measured;
                        }
                        hourObject.costs = hourValue.cost_measured;
                    }

                    hourData.push(hourObject);
                }
                observer.next({
                    consumption: (consumption) / 1000,
                    costs,
                    hours: hourData.reverse()
                });
            } catch (error) {
                observer.error(error);
            }
            observer.complete();
        });
    }


    /**
     * Determine the current trend based on the fetched and aligned data
     * @param values
     */
    private determineTrend$(values: TodayData): Observable<TodayData> {
        return new Observable((observer) => {
            try {
                const todayValues = values.today;
                const comparisonDateValues = values.comparisonDate;

                const todayData = initialTodayData;
                todayData.today = todayValues;
                todayData.comparisonDate = comparisonDateValues;

                let percentage = 0;
                let trendDirection = 0;
                let scale = 0;
                let leftScale = 1;
                let rightScale = 1;
                if (todayValues.consumption > comparisonDateValues.consumption) {
                    const temp = ((todayValues.consumption - comparisonDateValues.consumption)
                        / comparisonDateValues.consumption) * 100;
                    percentage = Math.round(temp);
                    trendDirection = 1;
                    scale = percentage > 80 ? 20 : 100 - percentage;
                    leftScale = scale / 100;
                    rightScale = 1;
                } else if (todayValues.consumption < comparisonDateValues.consumption) {
                    const temp = todayValues.consumption / comparisonDateValues.consumption;
                    percentage = Math.round((1 - (temp)) * 100);
                    trendDirection = -1;
                    scale = percentage > 80 ? 20 : 100 - percentage;
                    leftScale = 1;
                    rightScale = scale / 100;
                }

                todayData.trend.direction = trendDirection;
                todayData.trend.percentage = percentage;
                todayData.trend.scale.left = leftScale;
                todayData.trend.scale.right = rightScale;

                // determine ui state
                if (comparisonDateValues.consumption === 0 && todayValues.consumption === 0) {
                    observer.next(todayData);
                }

                if (comparisonDateValues.consumption > todayValues.consumption) {
                    todayData.leftState = 'inactive';
                    todayData.rightState = 'active';
                } else if (comparisonDateValues.consumption < todayValues.consumption) {
                    todayData.rightState = 'higher';
                    todayData.leftState = 'inactive';
                } else if (comparisonDateValues.consumption === todayValues.consumption) {
                    todayData.rightState = 'active';
                    todayData.leftState = 'active';
                }

                observer.next(todayData);
            } catch (error) {
                observer.error(error);
            }
            observer.complete();
        });

    }


    /**
     * Requests the data for the today tile.
     * The raw data from the API is then aligned to fit the SingleDayComparisonData interface
     * @private
     */
    private requestAndAlignData$(
        specifiedComparisonDate: Date,
        includeNotReachedHours = false
    ): Observable<TodayData> {
        const comparisonDateOffset = moment()
            .diff(specifiedComparisonDate, 'days');
        return zip([
            this.electricityService.getConsumptionForDay(0),
            this.electricityService.getConsumptionForDay(comparisonDateOffset)
        ]).pipe(
            mergeMap(([today, comparisonDate]) => {
                return zip([
                    this.alignRawData$(today, includeNotReachedHours),
                    this.alignRawData$(comparisonDate, includeNotReachedHours)
                ]);
            }),
            map(([today, comparisonDate]) =>
                ({
                    today: {
                        ...today,
                        date: moment().toDate()
                    },
                    comparisonDate: {
                        ...comparisonDate,
                        date: specifiedComparisonDate
                    }
                })
            )
        );
    }

}
