import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of, Subject, Subscription, timer} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {UserService} from './user.service';
import {ApplicationService} from './application.service';
import * as moment from 'moment';
import {BaseResponse} from '../shared/interfaces/base-response.interfaces';
import {AccountRewriteService} from './account-rewrite.service';
import {
    BillPredictionResponseData,
    ConsumptionResponseData,
    SingleDayConsumptionItem
} from '../shared/interfaces/plain-responses/electricity-response.interface';
import {TranslateService} from '@ngx-translate/core';


@Injectable({
    providedIn: 'root'
})
export class ElectricityService extends BaseService {
    onConsumption24hUpdate = new Subject<any>();
    onConsumptionFilteredUpdate = new Subject<any>();

    private updateRate = 10000;

    private hoursTimerSub: Subscription = null;
    private daysTimerSub: Subscription = null;
    private last24hTimerSub: Subscription = null;
    private filteredTimerSub: Subscription = null;

    private requestDateFormat = 'YYYY-MM-DD';
    private requestDateFormatMonths = 'YYYY-MM';

    private currentFilter = {
        offset: 24 * 60,
        limit: 0,
        interval: 24 * 60 * 60,
        level: 1
    };


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService,
                private accountRewrite: AccountRewriteService,
                private translate: TranslateService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();
        if (this.hoursTimerSub) {
            this.hoursTimerSub.unsubscribe();
            delete this.hoursTimerSub;
        }
        if (this.daysTimerSub) {
            this.daysTimerSub.unsubscribe();
            delete this.daysTimerSub;
        }
        if (this.last24hTimerSub) {
            this.last24hTimerSub.unsubscribe();
            delete this.last24hTimerSub;
        }
        if (this.filteredTimerSub) {
            this.filteredTimerSub.unsubscribe();
            delete this.filteredTimerSub;
        }
    }


    setCurrentFilter(offset: number, limit: number, interval: number, level: number): void {
        this.currentFilter.offset = offset;
        this.currentFilter.limit = limit;
        this.currentFilter.interval = interval;
        this.currentFilter.level = level;

        const temp = this.getFilteredConsumption().subscribe((res) => {
                if (!res) {
                    return;
                }
                this.onConsumptionFilteredUpdate.next(res);
                temp.unsubscribe();
            },
            error => console.log('Error:', error),
            () => temp.unsubscribe()
        );

    }


    /**
     * Start an update on the consumption values for the last 24 hours
     */
    startLast24hTimerUpdate(): void {
        if (this.last24hTimerSub) {
            return;
        }
        this.last24hTimerSub = timer(0, this.updateRate).pipe(
            mergeMap((cycle) => this.getConsumptionFor24Hours())
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onConsumption24hUpdate.next(res);
                }
            }
        );
    }


    /**
     * Start an update on the consumption values for the last 24 hours
     */
    startFilteredConsumptionUpdate(): void {
        if (this.filteredTimerSub) {
            return;
        }
        this.filteredTimerSub = timer(0, this.updateRate).pipe(
            mergeMap((cycle) => this.getFilteredConsumption())
        ).pipe(
            map((res) => res),
            catchError((error: any) => this.handleError(error))
        ).subscribe(
            (res) => {
                if (res) {
                    this.onConsumptionFilteredUpdate.next(res);
                }
            }
        );
    }


    /**
     * Get Consumption filtered by
     */
    getFilteredConsumption(): Observable<any> {
        let timeframe = 'hours';
        if (this.currentFilter.interval > 24 * 60) {
            timeframe = 'days';
            if (this.user.isEDGUser()) {
                // if the filter defines weeks
                if (this.currentFilter.interval === 3600) {
                    timeframe = 'hours';
                }
            }
        } else {
            if (this.user.isEDGUser()) {
                timeframe = 'quarter-hours';
            }
        }
        const from: any = new Date();
        from.setMinutes(from.getMinutes() - this.currentFilter.offset);

        const to: any = new Date();
        to.setMinutes(to.getMinutes() - this.currentFilter.limit);

        const from_s = moment(from).format(this.requestDateFormat);
        const to_s = moment(to).format(this.requestDateFormat);

        const suffix = `${timeframe}/${from_s}/${to_s}`;
        return this.getConsumption(suffix);
    }


    /**
     * Returns the current bill prediction.
     */
    getBillPrediction(): Observable<BillPredictionResponseData> {
        let url = this.API_BASE_URL + constants.api.routes.electricity.bill.prediction;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.billPrediction}.json`;
        }
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    /**
     * Returns the consumption for a specific date.
     *
     * If the application is in demo mode - the data is loaded from a local file.
     * The entire datasets timestamps are replaced with dates starting from today going back in time
     * one day for each item in the array. That way we can simulate a dataset for each day being
     * present. After aligning the timestamps a corresponding entry is searched for the requested
     * date.
     *
     * This method is used for the meter detail view.
     *
     * The filtering approach is not really efficient, however it's the most feasible way to fake
     * data in demo mode. Demo mode sucks anyway so it's fine.
     * The location of this operation is not really elegant, I get that. But currently I have not
     * the time to refactor this, or rather think about a better solution.
     */
    getConsumptionForMeterDetailSpecificDate(date: string): Observable<number> {
        const currentLang = this.translate.currentLang;
        if (this.application.isDemoMode()) {
            const parsedRequestDate = moment(date).toDate();
            return this.getConsumptionDemoData().pipe(
                map((data) => {
                    const reversedData = data.reverse();
                    reversedData.forEach((item, idx) => {
                        const startTimeCpy = moment().subtract(idx, 'days');
                        reversedData[idx].timestamp = startTimeCpy.toDate().toString();
                    });

                    const filteredData = reversedData.filter((item) => {
                        const elementDate = moment(item.timestamp)
                            .locale(currentLang).startOf('day');
                        const dayMatch = elementDate.toDate().getDate() ===
                            parsedRequestDate.getDate();
                        const monthMatch = elementDate.toDate().getMonth() ===
                            parsedRequestDate.getMonth();
                        const yearMatch = elementDate.toDate().getFullYear() ===
                            parsedRequestDate.getFullYear();
                        return dayMatch && monthMatch && yearMatch;
                    });

                    return filteredData[0].current_summation;
                })
            );
        }

        return this.getConsumption(date, this.accountRewrite.accountRewriteEnabled()).pipe(
            map((data) => data.current_summation)
        );
    }


    /**
     * Returns the consumption for a specific day
     * @param dayOffset - in days
     */
    getConsumptionForDay(dayOffset: number): Observable<ConsumptionResponseData[]> {
        if (this.application.isDemoMode()) {
            let dataset = constants.demo.files.consumptionHours;
            if (dayOffset !== 0) {
                dataset = constants.demo.files.consumptionHoursLastWeek;
            }
            const url = `assets/data/demo/${dataset}.json`;
            return this.http.get(url).pipe(
                map((data: BaseResponse) => data.data)
            );
        }
        const date = new Date();
        date.setDate(date.getDate() - dayOffset);
        const method = moment(date).format(this.requestDateFormat);
        return this.getConsumption(`hours/${method}/${method}`);
    }


    /**
     * Request the consumption for the last 24 hours, mostly consisting of 2 days
     */
    getConsumptionFor24Hours(): Observable<any> {
        const today = moment();
        const yesterday = moment().subtract(1, 'day');
        let method = 'hours';
        if (this.user.isEDGUser()) {
            method = 'quarter-hours';
        }
        const yesterdayStr = yesterday.format(this.requestDateFormat);
        const todayStr = today.format(this.requestDateFormat);
        const today_suffix = `${method}/${yesterdayStr}/${todayStr}`;
        return this.getConsumption(today_suffix);
    }


    /**
     * Returns the consumption for a specific timeframe
     */
    getConsumptionForTimeframe(
        from, to, timeframe
    ): Observable<ConsumptionResponseData[]> {
        const dateFrom = new Date(from);
        const dateTo = new Date(to);

        let method = null;
        switch (timeframe) {
            case 'months': {
                const dFromStr = moment(dateFrom).format(this.requestDateFormatMonths);
                const dToStr = moment(dateTo).format(this.requestDateFormatMonths);
                method = `${dFromStr}/${dToStr}`;
                break;
            }
            default: {
                const dFromStr = moment(dateFrom).format(this.requestDateFormat);
                const dToStr = moment(dateTo).format(this.requestDateFormat);
                method = `${dFromStr}/${dToStr}`;
                break;
            }
        }

        return this.getConsumption(`${timeframe}/${method}`);
    }


    getMonthlyConsumption(): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.electricity.consumption.compareMonth;
        return this.http.get(url).pipe(
            map((res) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    getFeedinForTimeframe(from, to, timeframe): Observable<any> {
        let method = null;
        switch (timeframe) {
            case 'months': {
                const dFromStr = moment(from).format(this.requestDateFormatMonths);
                const dToStr = moment(to).format(this.requestDateFormatMonths);
                method = `${dFromStr}/${dToStr}`;
                break;
            }
            default: {
                const dFromStr = moment(from).format(this.requestDateFormat);
                const dToStr = moment(to).format(this.requestDateFormat);
                method = `${dFromStr}/${dToStr}`;
                break;
            }
        }
        return this.getFeedin(`${timeframe}/${method}`);
    }


    /**
     * Returns the feedin for a specific day.
     * @param date
     */
    getFeedinDataForMeterDetailSpecificDate(date: Date): Observable<number> {
        const dateParsed = moment(date).format(this.requestDateFormat);
        if (this.application.isDemoMode()) {
            return of(undefined);
        }
        return this.getFeedin(`${dateParsed}`).pipe(
            map((data) => {
                if ('current_summation' in data) {
                    return data.current_summation;
                }
                return undefined;
            })
        );
    }


    /**
     * Main Function to request consumption
     */
    private getConsumption(method: string, useRewriteUrl = false): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.electricity.consumption.this;
        if (useRewriteUrl) {
            url = this.ACCOUNT_REWRITE_BASE_URL + constants.api.routes.electricity.consumption.this;
        }
        url += '/' + method;

        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    /**
     * Secondary function to retrieve fed energy values
     * @param method
     */
    private getFeedin(method: string): Observable<any> {
        const url = `${this.API_BASE_URL}${constants.api.routes.feedin.electricity}/${method}`;
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    /**
     * Returns the demo consumption file.
     * This is only to be seen as a temporary solution.
     * todo: refactor this in some way - i don't know how yet
     * @private
     */
    private getConsumptionDemoData(): Observable<Array<SingleDayConsumptionItem>> {
        const url = `assets/data/demo/${constants.demo.files.consumption}.json`;
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }
}
