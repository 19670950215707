<div class="tile-container">
    <div class="tile" angulartics2On="click" angularticsCategory="Screens" angularticsAction="screen_view"
         angularticsLabel="screen: Abschlags-Check; previous_screen: Übersicht"
         (click)="onTileClicked()">

        <div class="tile-inner">
            <h3 class="tile-heading">
                {{LABELS.title}}
            </h3>

            <ng-container *ngIf="householdComparisonData$ | async as householdComparisonData">
                <div class="tile-stats p-v-s">
                    <ng-container *ngIf="householdComparisonData.viewState === ViewState.SUCCESS">
                        <div class="current-consumption">
                            {{householdComparisonData.consumption | number:'1.0-0':'de-DE' }}
                            {{LABELS.unitConsumption}}
                        </div>
                        <div class="current-rank">
                            <i class="rank-m m-r-s"></i>
                            {{householdComparisonData.rank | largeInt }}
                        </div>
                    </ng-container>
                </div>

                <div class="tile-content col">
                    <div class="tile-chart-abs">
                        <ng-container [ngSwitch]="householdComparisonData.viewState">
                            <ng-container *ngSwitchCase="ViewState.SUCCESS">
                                <app-household-comparison-chart
                                    [diagramData]="householdComparisonData.diagramData">
                                </app-household-comparison-chart>
                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.LOADING">
                                <div class="inflate center-content">
                                    {{LABELS.titleLoadingState}}
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.ERROR">
                                <div class="inflate empty-state-container">
                                    <div class="empty-state-img"></div>
                                    <div class="empty-state-label">
                                        {{LABELS.titleErrorState}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.EMPTY">
                                <div class="inflate empty-state-container">
                                    <div class="empty-state-img"></div>
                                    <div class="empty-state-label">
                                        {{LABELS.titleEmptyState}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.OPT_OUT">
                                <div class="inflate empty-state-container">
                                    <div class="empty-state-img"></div>
                                    <div class="empty-state-label">
                                        {{LABELS.titleOptOutState}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.NO_DATA">
                                <div class="inflate empty-state-container">
                                    <div class="no-data-img"></div>
                                    <div class="empty-state-label">
                                        {{LABELS.noDataState}}
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.NO_DATA">
                                <div class="inflate empty-state-container">
                                    <div class="no-data-img"></div>
                                    <div class="empty-state-label">
                                        {{LABELS.TILE_NO_DATA_STATE}}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
