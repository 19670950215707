<div class="date-input" [ngClass]="alternativeClass" (click)="onButtonClicked()">
    <input #dateSelect id="date-input" type="date"
           [min]="determineMinDate()" [max]="maxDate"
           [value]="formatDateForInput()"
           (change)="onDateChange(dateSelect.value)">

    <label #label [class.without-icon]="!hasIcon"
           for="date-input">
        {{determineLabelContents()}}
    </label>

    <ng-container *ngIf="hasIcon">
        <div class="icon-container">
            <i class="chevron-down-s"></i>
        </div>
    </ng-container>
    <ng-container *ngIf="showCalendarIcon">
        <div class="icon-container">
            <i class="calendar-icon-m"></i>
        </div>
    </ng-container>
</div>
