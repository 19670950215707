import {AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Chart} from 'angular-highcharts';
import {Globals} from '../../../services/globals.service';
import {MockDataService} from '../../../services/mock-data.service';
import {UserService} from '../../../services/user.service';
import {getMonthName} from '../../../lib/DateUtil';
import {ElectricityService} from '../../../services/electricity.service';
import {ApplicationService} from '../../../services/application.service';
import {TILE_TYPE, TileService} from '../../../services/tile.service';
import * as moment from 'moment';
import {TrackAnalyticsService} from '../../../services/track-analytics.service';
import {SeriesBarOptions} from 'highcharts';
import {TranslateService} from '@ngx-translate/core';


@Component({
    selector: 'app-comparison-tile',
    templateUrl: './comparison-tile.component.html',
    styleUrls: ['./comparison-tile.component.scss'],
    providers: [Globals]
})

export class CompareTileComponent implements OnInit, AfterViewInit {
    private readonly type: TILE_TYPE = TILE_TYPE.COMPARISON;
    private readonly colors = {
        lower: '#d27300',
        higher: '#f59b00'
    };

    chart: Chart = null;

    trend = {
        monthname: null,
        direction: 0,
        percent: 0
    };

    formerValues = {
        name: moment().subtract(1, 'month').format('01.MM. - DD.MM.YY'),
        y: 100,
        color: '#e8e8e8',
        empty: true,
        higher: false,
        lower: false,
    };

    currentValues = {
        name: this.translate.instant('screens.dashboard.months')[moment().month()],
        y: 100,
        color: '#e8e8e8',
        empty: true,
        higher: false,
        lower: false,
    };

    hideLabels = true;

    showDiagrams = true;
    diagramInitialized = false;
    originalChartContainerHeight = 0;

    @ViewChild('p', {static: true}) chartContainer: ElementRef;


    constructor(private analytics: TrackAnalyticsService,
                private globals: Globals,
                private mockDataService: MockDataService,
                private userService: UserService,
                private electricityService: ElectricityService,
                private application: ApplicationService,
                private tiles: TileService,
                private renderer: Renderer2,
                private translate: TranslateService) {
    }


    ngOnInit() {
        this.renderer.listen('window', 'resize', (event) => {
            if (this.chart.ref) {
                this.chart.ref.setSize(null, this.chartContainer.nativeElement.clientHeight);
            }
        });
        this.initializeChart();
    }


    ngAfterViewInit() {
        this.originalChartContainerHeight = this.chartContainer.nativeElement.clientHeight;
    }


    onTileClicked(): void {
        this.detailEntered();
        this.tiles.openDetailView(this.type);
    }


    onDiagramLoaded(chart): void {
        this.diagramInitialized = true;

        if (this.application.isDemoMode()) {
            this.getMockComparison();
            return;
        }

        this.electricityService.getMonthlyConsumption().subscribe(
            (result) => {
                const former = result.historic_summation_delta;
                const current = result.current_summation_delta;
                this.calculateConsumption(current, former);
            }
        );
    }


    getMockComparison(): void {
        const s = this.mockDataService.getMonthlyComparison().subscribe(
            (data) => {
                this.calculateConsumption(data.data[0].measured, data.data[1].measured);
                s.unsubscribe();
            }
        );
    }


    /**
     * Calculate Consumption
     */
    calculateConsumption(consumptionCurrent, consumptionCompare) {
        this.showDiagrams = consumptionCurrent > 0 || consumptionCompare > 0;

        // reset values

        const former = this.formerValues;
        former.y = Math.round(consumptionCompare / 1000);

        const current = this.currentValues;
        current.y = Math.round(consumptionCurrent / 1000);


        const currentHigher = current.y > former.y;
        current.higher = currentHigher;
        current.lower = !currentHigher;
        former.higher = !currentHigher;
        former.lower = currentHigher;

        if (former.y === 0 && current.y > 0) {
            former.y = current.y;
            former.color = this.colors.higher;
            current.color = this.colors.lower;
            former.empty = true;
            current.empty = false;
        } else if (current.y === 0 && former.y > 0) {
            // current.y = current.y;
            current.color = this.colors.higher;
            former.color = this.colors.lower;
            current.empty = true;
            former.empty = false;
        } else if (current.y > 0 && former.y > 0) {
            former.color = this.colors.higher;
            current.color = this.colors.lower;
            current.empty = false;
            former.empty = false;
        } else if (current.y === 0 && former.y === 0) {
            former.y = 100;
            current.y = 100;
        }

        this.formerValues = former;
        this.currentValues = current;
        this.chart.removeSeries(0);
        this.chart.removeSeries(1);
        const series = {
            name: 'current',
            data: [
                {
                    name: 'former',
                    x: 0,
                    y: this.formerValues.y,
                    color: this.formerValues.color,
                },
                {
                    name: 'current',
                    x: 1,
                    y: this.currentValues.y,
                    color: this.currentValues.color,
                }
            ]
        } as SeriesBarOptions;
        this.chart.addSeries(series, true, false);


        let newMonthName = '';
        getMonthName(1, this.translate).subscribe(monthName => {
            newMonthName = monthName;
        });

        if (consumptionCurrent > consumptionCompare) {
            this.trend = {
                monthname: newMonthName,
                direction: 1,
                percent: Math.round(((consumptionCurrent - consumptionCompare) / consumptionCompare) * 100)
            };
        } else if (consumptionCurrent < consumptionCompare) {
            this.trend = {
                monthname: newMonthName,
                direction: -1,
                percent: Math.round((1 - (consumptionCurrent / consumptionCompare)) * 100)
            };
        }
    }


    private trackDetailsEntered(): void {
        this.analytics.trackEvent({
            action: 'dashboard_tile_tapped',
            properties: {
                category: 'Tiles',
                label: 'Tile: Comparison'
            }
        });
    }


    /**
     * Init chart
     */
    private initializeChart(): void {
        const self = this;
        this.chart = new Chart({
            chart: {
                type: 'column',
                backgroundColor: 'rgba(255, 255, 255, 0)',
                margin: [0, 0, 0, 0],
                height: '70%',
                events: {
                    load(evt) {
                        this.setSize(null, self.originalChartContainerHeight, false);
                        self.onDiagramLoaded(this);
                    },
                },

            },
            title: {
                text: null
            },
            xAxis: {
                title: {text: null},
                labels: {enabled: false},
                lineColor: 'transparent',
                tickColor: 'transparent'
            },
            yAxis: {
                title: {text: null},
                labels: {enabled: false},
                gridLineWidth: 0
            },
            tooltip: {
                enabled: false
            },
            plotOptions: {
                column: {
                    borderRadius: 5,
                    dataLabels: {enabled: false},
                    states: {hover: {enabled: false}},
                },
                series: {
                    showInLegend: false,
                }
            },
            series: [
                {
                    data: null,
                    type: 'column',
                    name: null
                }
            ],
            credits: {
                enabled: false
            }
        });
    }


    private trackFirstDetailView(): void {
        this.analytics.trackEvent({
            action: 'first_detail_view',
            properties: {
                category: 'Screens',
                label: 'Screen: MVP-Details'
            }
        });
    }


    private detailEntered() {
        if (!(this.globals.getFirstDetailsViewed())) {
            this.trackFirstDetailView();
        }
        this.globals.setFirstDetailsViews();
        this.trackDetailsEntered();
    }


}
