<div class="overlay today-overlay">
    <div class="detail-overlay">
        <div class="detail-view">

            <section class="detail-head" [style.background]="headerColor">
                <header class="detail-header">
                    <h1 class="detail-heading">
                        {{ LABELS.TITLE }}
                    </h1>

                    <button class="iona-icon-only-button info white"
                            angulartics2On="click" angularticsCategory="Detailansichten"
                            angularticsAction="detail_info_text" angularticsLabel="Heute"
                            (click)="infoVisible = !infoVisible">
                    </button>

                    <button class="iona-icon-only-button close white" (click)="close()"></button>
                </header>
            </section>

            <section class="detail-info today-info" *ngIf="infoVisible">
                <div class="icon">
                    <i class="icon-info"></i>
                </div>
                <div class="info-text">
                    <h2 class="font-bold">{{ TEXTS.INFO.TITLE }}</h2>
                    <p *ngFor="let p of TEXTS.INFO.PARAGRAPHS_IONA">
                        {{ p }}
                    </p>
                </div>
            </section>
            <section class="detail-content">
                <div class="today-content">
                    <ng-container *ngIf="todayTileData$ | async as detailData">
                        <ng-container [ngSwitch]="detailData.viewState">
                            <ng-container *ngSwitchDefault>
                                <div class="">
                                    <div class="">
                                        <app-date-picker-button
                                            [alternativeClass]="'date-picker-today'"
                                            [currentDate]="detailData.comparisonDate.date"
                                            (dateChanged)="onComparisonDateChange($event)"
                                            [hasIcon]="false"
                                            [showCalendarIcon]="true"
                                        >
                                        </app-date-picker-button>
                                    </div>
                                </div>
                                <div class="today-visualization grow-space">
                                    <div class="pie-wrapper">
                                        <div class="today-pie left {{detailData.leftState}}" [halfCircle]="'left'"
                                             [style.transform]="'scale(' + detailData.trend.scale.left + ')'"></div>
                                        <div class="today-pie right {{detailData.rightState}}"
                                             [halfCircle]="'right'"
                                             [style.transform]="'scale(' + detailData.trend.scale.right + ')'"></div>
                                    </div>
                                </div>

                                <div class="today-stats-wrapper">
                                    <div class="today-stats">
                                        <div class="muted">
                                            <div class="font-regular day right m-b-s">
                                                {{ detailData.comparisonDate.date | date: 'dd.MM.yyyy' }}
                                            </div>
                                            <div *ngIf="!isVisionUser" class="font-regular cost-left m-b-s">
                                                {{ detailData.comparisonDate.costs | todayValueFormat }}
                                                {{ LABELS.UNIT_COST }}
                                            </div>
                                            <div class="font-regular cost-left m-b-s"
                                                 [ngClass]="{'consumption-value': isVisionUser}">
                                                {{ detailData.comparisonDate.consumption | todayValueFormat }}
                                                {{ LABELS.UNIT_CONSUMPTION }}
                                            </div>
                                        </div>
                                        <div class="black">
                                            <div class="font-regular day left m-b-s">
                                                {{ LABELS.LABEL_COMPARISON_TODAY }}
                                            </div>
                                            <div *ngIf="!isVisionUser" class="font-regular cost-right m-b-s">
                                                {{ detailData.today.costs | todayValueFormat }}
                                                {{ LABELS.UNIT_COST }}
                                            </div>
                                            <div class="font-regular cost-right m-b-s"
                                                 [ngClass]="{'consumption-value': isVisionUser}">
                                                {{ detailData.today.consumption | todayValueFormat }}
                                                {{ LABELS.UNIT_CONSUMPTION }}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="today-stats-summary font-regular"
                                         [ngClass]="{'no-border': isVisionUser}">
                                        <div *ngIf="!!detailData.trend.percentage">
                                            <mat-icon class="material-icons-outlined bulb-icon">wb_incandescent_outline
                                            </mat-icon>
                                            <ng-container
                                                *ngIf="detailData.trend.percentage > 0 && detailData.trend.percentage < 9999">
                                                <strong>
                                                    {{ detailData.trend.percentage }}
                                                    {{ LABELS.LABEL_FRAGMENT_PERCENT }}
                                                    {{ detailData.trend.direction > 0 ? LABELS.MORE : LABELS.LESS }}
                                                    &nbsp;
                                                </strong>
                                                <ng-container *ngIf="!dateChanged">
                                            <span>
                                                {{ LABELS.LABEL_FRAGMENT_LAST }}
                                                {{ detailData.comparisonDate.date | nameOfDay }}
                                            </span>
                                                </ng-container>
                                                <ng-container *ngIf="dateChanged">
                                            <span>
                                                {{ LABELS.LABEL_FRAGMENT_COMPARED_TO }}
                                                {{ detailData.comparisonDate.date | date:'dd.MM.yyyy' }}
                                            </span>
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngIf="detailData.trend.percentage === 0">
                                                <strong>{{ LABELS.IDENTICAL }}</strong>
                                                &nbsp;
                                                <ng-container *ngIf="!dateChanged">
                                            <span>
                                                {{ LABELS.LABEL_FRAGMENT_TO_LAST }}
                                                {{ detailData.comparisonDate.date | nameOfDay }}
                                            </span>
                                                </ng-container>
                                                <ng-container *ngIf="dateChanged">
                                            <span>
                                                {{ LABELS.LABEL_FRAGMENT_TO }}
                                                {{ detailData.comparisonDate.date | date:'dd.MM.yyyy' }}
                                            </span>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngSwitchCase="ViewState.ERROR">
                                <div class="center-contents">
                                    {{ LABELS.LABEL_ERROR_STATE }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="divider"></div>
                <div class="lists-wrapper">
                    <h1>{{ LABELS.OVERVIEW_HOURS }}</h1>
                    <div class="list-header">
                        <div class="list-time">
                            {{ 'screens.dashboard.live.time' | translate }}
                        </div>
                        <div class="list-date">
                            {{ 'screens.dashboard.comparison.consumption' | translate }}
                        </div>
                        <div class="list-consumption">
                            {{ LABELS.UNIT_CONSUMPTION }}
                        </div>
                        <div *ngIf="!isVisionUser" class="list-cost">
                            {{ LABELS.UNIT_COST }}
                        </div>
                    </div>
                    <div class="lists-container">
                        <div class="accordion">
                            <ng-container *ngIf="combinedData$ | async as detailData">
                                <div class="accordion-item">
                                    <a (click)="toggleAccordion('futureValues')" class="accordion-header">
                                        <span class="accordion-title">
                                            {{ 'screens.dashboard.todayDetail.sections.showFutureValue' | translate }}
                                        </span>
                                        <mat-icon *ngIf="isAccordionOpen['futureValues']" class="icon-accordion">
                                            expand_less
                                        </mat-icon>
                                        <mat-icon *ngIf="!isAccordionOpen['futureValues']" class="icon-accordion">
                                            expand_more
                                        </mat-icon>
                                    </a>
                                    <div *ngIf="isAccordionOpen['futureValues']" class="accordion-content">
                                        <ng-container [ngSwitch]="detailData.full.viewState">
                                            <ng-container *ngSwitchDefault>
                                                <ul class="hours-list">
                                                    <li *ngFor="let hour of detailData.full.today.hours; index as i">
                                                        <div>
                                                            <div class="list-time">
                                                                {{ hour.hour }} {{ LABELS.HOUR_START_SUFFIX }}
                                                                {{ LABELS.TIME_DIVIDER }}
                                                                {{ hour.hour }} {{ LABELS.HOUR_END_SUFFIX }}
                                                            </div>
                                                            <div class="list-date">
                                                                <mat-icon class="light-green-icon">square</mat-icon>
                                                                {{ LABELS.LIST_TODAY }}
                                                            </div>
                                                            <div class="list-consumption">
                                                                {{ hour.consumption | todayValueFormat }}
                                                            </div>
                                                            <div *ngIf="!isVisionUser" class="list-cost">
                                                                {{ hour.costs | todayValueFormat }}
                                                            </div>
                                                        </div>
                                                        <div class="comparison-hours">
                                                            <div class="list-time"></div>
                                                            <div class="list-date">
                                                                <mat-icon class="dark-green-icon">square</mat-icon>
                                                                {{ detailData.full.comparisonDate.date | date:'dd.MM.yyyy' }}
                                                            </div>
                                                            <div class="list-consumption">
                                                                {{ detailData.full.comparisonDate.hours[i].consumption | todayValueFormat }}
                                                            </div>
                                                            <div *ngIf="!isVisionUser" class="list-cost">
                                                                {{ detailData.full.comparisonDate.hours[i].costs | todayValueFormat }}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="ViewState.ERROR">
                                                <div class="center-content">
                                                    {{ LABELS.LABEL_ERROR_STATE }}
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                        <div class="static-section">
                            <ng-container *ngIf="combinedData$ | async as detailData">
                                <div class="accordion-item">
                                    <div class="accordion-header">
                                        {{ 'screens.dashboard.comparison.currentValues.name' | translate }}
                                    </div>
                                    <div class="accordion-content">
                                        <ng-container [ngSwitch]="detailData.detail.viewState">
                                            <ng-container *ngSwitchDefault>
                                                <ul class="hours-list">
                                                    <li *ngFor="let hour of detailData.detail.today.hours; index as i">
                                                        <div>
                                                            <div class="list-time">
                                                                {{ hour.hour }} {{ LABELS.HOUR_START_SUFFIX }}
                                                                {{ LABELS.TIME_DIVIDER }}
                                                                {{ hour.hour }} {{ LABELS.HOUR_END_SUFFIX }}
                                                            </div>
                                                            <div class="list-date">
                                                                <mat-icon class="light-green-icon">square</mat-icon>
                                                                {{ LABELS.LIST_TODAY }}
                                                            </div>
                                                            <div class="list-consumption">
                                                                {{ hour.consumption | todayValueFormat }}
                                                            </div>
                                                            <div *ngIf="!isVisionUser" class="list-cost">
                                                                {{ hour.costs | todayValueFormat }}
                                                            </div>
                                                        </div>
                                                        <div class="comparison-hours">
                                                            <div class="list-time"></div>
                                                            <div class="list-date">
                                                                <mat-icon class="dark-green-icon">square</mat-icon>
                                                                {{ detailData.detail.comparisonDate.date | date:'dd.MM.yyyy' }}
                                                            </div>
                                                            <div class="list-consumption">
                                                                {{ detailData.detail.comparisonDate.hours[i].consumption | todayValueFormat }}
                                                            </div>
                                                            <div *ngIf="!isVisionUser" class="list-cost">
                                                                {{ detailData.detail.comparisonDate.hours[i].costs | todayValueFormat }}
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="ViewState.ERROR">
                                                <div class="center-content">
                                                    {{ LABELS.LABEL_ERROR_STATE }}
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>
