<mat-dialog-content class="modal-content">
    <form>
        <button class="close-button" (click)="onClose()">
            <img src="assets/img/icons/close.png" alt="{{ 'common.close' | translate }}" width="30px">
        </button>
        <h2 mat-dialog-title>{{ 'screens.settings.language.title' | translate }}</h2>
        <div>
            <p class="eon-regular">{{ 'screens.settings.language.description' | translate }}</p>
            <mat-radio-group [(ngModel)]="selectedLanguage" name="language" (change)="onSelect()">
                <mat-radio-button *ngFor="let language of languages" [value]="language.key" color="primary">
                    {{ language.displayTextKey | translate }}
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <button class="iona-button orange" [disabled]="isSaveDisabled()" (click)="onSave()">{{ 'screens.settings.language.changeButton' | translate }}</button>
    </form>
</mat-dialog-content>
