import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-support-modal',
    templateUrl: './support-modal.component.html',
    styleUrls: ['./support-modal.component.scss']
})
export class SupportModalComponent {
    modalText: string;
    isHtmlText: boolean;
    imageUrl: string;
    buttonText: string;
    alignLeft: boolean;
    morePadding: boolean;
    title: string;
    showCloseButton: boolean;
    fontSize: string;
    titleFontSize: string;

    constructor(
        public dialogRef: MatDialogRef<SupportModalComponent>,
        private translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.modalText = data.text;
        this.imageUrl = data.imageUrl || '';
        this.isHtmlText = data.isHtmlText || false;
        this.alignLeft = data.alignLeft || false;
        this.morePadding = data.morePadding || false;
        this.buttonText = data.buttonText || this.translate.instant('common.close');
        this.title = data.title || '';
        this.showCloseButton = data.showCloseButton || true;
        this.fontSize = data.fontSize || '20px';
        this.titleFontSize = data.titleFontSize || '24px';
    }

    closeModal(): void {
        this.dialogRef.close();
    }
}
