import {Component, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {static_links} from '../../../shared/constants/web-resources.constants';
import {UserService} from '../../../services/user.service';
import * as moment from 'moment';
import {ApplicationService} from '../../../services/application.service';
import {AnimationOptions} from 'ngx-lottie';
import {FinanceDataService} from '../../../services/finance-data.service';
import {ignoreElements, Observable, of, share} from 'rxjs';
import {catchError} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-finance-details',
    templateUrl: './finance-details.component.html',
    styleUrls: ['./finance-details.component.scss'],
    viewProviders: [ApiService]
})

export class FinanceDetailsComponent extends BasePopover implements OnInit {
    LABELS: any;
    TEXTS: any;

    financeData$ = this.financeDataService.getFinancialTrend().pipe(
        share()
    );
    financeDataError$: Observable<boolean> = this.financeData$.pipe(
        ignoreElements(),
        catchError(() => of(true))
    );

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/finance-detail-2.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Smart Meter'
    };

    infoVisible = false;

    constructor(protected popoverRef: PopoverRef,
                public userService: UserService,
                private application: ApplicationService,
                private financeDataService: FinanceDataService,
                private translate: TranslateService) {
        super(popoverRef);
    }

    ngOnInit() {
        this.translate.get('screens.dashboard.financeDetail.labels').subscribe((labels: any) => {
            this.LABELS = labels;
        });

        this.translate.get('screens.dashboard.financeDetail.texts').subscribe((texts: any) => {
            this.TEXTS = texts;
        });
    }

    /**
     * Format the end date for beginning of the current year
     * @param date
     */
    formatStartDate(date: Date): string {
        if (this.application.isDemoMode()) {
            const now = new Date();
            return `01.01.${now.getFullYear()}`;
        }
        return this.formatDate(date);
    }


    /**
     * Format the end date for end of the current year
     * @param date
     */
    formatEndDate(date: Date): string {
        if (this.application.isDemoMode()) {
            const now = new Date();
            return `31.12.${now.getFullYear()}`;
        }
        return this.formatDate(date);
    }


    /**
     * Opens a static link
     */
    openLink(): void {
        const link = static_links.finance[this.userService.getActiveUserProvider()];
        window.open(link);
    }

    /**
     * Format date wrapper
     * @param date
     * @private
     */
    private formatDate(date: Date): string {
        const parsedDate = moment(date);
        const monthIndex = parsedDate.month();

        const translatedMonths = this.translate.instant('screens.dashboard.months');
        const translatedMonth = translatedMonths[Object.keys(translatedMonths)[monthIndex]];

        return parsedDate.format(`DD. ${translatedMonth} YYYY`);
    }
}
