<main class="register-container">

    <div class="info-btn iona-icon-only-button info-big" (click)="openFAQ()"></div>

    <!--
      -- ACCOUNT CREATION --------------------------------------
      -->
    <section class="white" *ngIf="currentStep === onboardingSteps.accountCreation">
        <div class="top-logo center-contents"></div>
        <form class="login-form"
              [formGroup]="voucherForm"
              (ngSubmit)="validateVoucher()">
            <div class="grow-space">
                <div class="btn-container">
                    <input
                        [formControl]="voucherForm.controls.email"
                        type="email"
                        name="email"
                        placeholder="{{ 'screens.register.onBoarding.accountCreation.emailLabel' | translate }}"
                        class="iona-input"
                    />
                </div>
                <div class="btn-container">
                    <input
                        [formControl]="voucherForm.controls.voucher"
                        type="text"
                        name="code"
                        placeholder="{{ 'common.code' | translate }}"
                        class="iona-input"
                    />
                </div>
            </div>

            <div class="center-contents btn-container">
                <button class="iona-button dark"
                        type="submit"
                        [disabled]="voucherForm.invalid">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" target="_self" routerLink="/">{{ 'common.login' | translate }}</a>
            </div>
        </form>
    </section>


    <!---- SET PASSWORD ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.passwordEntry">
        <div class="top-logo center-contents">
        </div>
        <h1>{{ 'screens.register.onBoarding.passwordEntry.heading' | translate }}</h1>
        <form class="login-form"
              [formGroup]="passwordForm"
              (ngSubmit)="registerUser()">
            <div class="grow-space">
                <div class="btn-container">
                    <div class="password-wrapper">
                        <input id="first-password"
                               [type]="passwordVisible1 ? 'text' : 'password'"
                               class="iona-input"
                               placeholder="{{ 'common.password' | translate }}"
                               formControlName="password1">
                        <button type="button" class="toggle-password" (click)="passwordVisible1 = !passwordVisible1">
                            <i [ngClass]="passwordVisible1 ? 'password-visible' : 'password-hidden'"></i>
                        </button>
                    </div>
                    <div class="password-info" (click)="openPasswordInfoModal()">
                        <i class="icon-info"></i>
                        <p>{{ 'screens.register.passwordPolicy.showPasswordPolicy' | translate }}</p>
                    </div>
                    <div class="password-strength">
                        <span *ngFor="let i of [0,1,2,3,4]"
                              [class.active]="i <= currentPasswordScore"></span>
                        <div class="password-score-label">
                            {{ 'common.password' | translate }}: <span>{{ determinePasswordScoreRatingText() }}</span>
                        </div>

                    </div>
                </div>

                <div class="btn-container">
                    <div class="password-wrapper">
                        <input id="second-password"
                               [type]="passwordVisible2 ? 'text' : 'password'"
                               class="iona-input"
                               placeholder="{{ 'screens.register.onBoarding.passwordEntry.confirmPasswordLabel' | translate }}"
                               formControlName="password2">
                        <button type="button" class="toggle-password" (click)="passwordVisible2 = !passwordVisible2">
                            <i [ngClass]="passwordVisible2 ? 'password-visible' : 'password-hidden'"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="btn-container center-contents">
                <button class="iona-button dark"
                        type="submit"
                        [disabled]="setPasswordDisabled">{{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>

    <!---- HARDWARE PRESENT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.hardwarePresent">
        <h1 class="m-b-x">{{ 'screens.register.onBoarding.hardwarePresent.heading' | translate }}?</h1>
        <div class=" checklist-container">
            <ul class="checklist">
                <li class="font-bold">{{ isSmartBox2() ? 'iONA Box 2.0' : 'iONA PowerChecker' }}</li>
                <li class="font-bold">{{ 'screens.register.onBoarding.hardwarePresent.checklist.opticalReader' | translate }}</li>
                <li *ngIf="userService.isERNAUser()"
                    class="font-bold">{{ 'screens.register.onBoarding.hardwarePresent.checklist.pin' | translate }}
                </li>
                <li class="font-bold">{{ 'screens.register.onBoarding.hardwarePresent.checklist.batteries' | translate }}</li>
                <li class="font-bold">{{ 'screens.register.onBoarding.hardwarePresent.checklist.meterAccess' | translate }}</li>
            </ul>
            <p class="subtext">{{ 'screens.register.onBoarding.hardwarePresent.subtext' | translate }}</p>
        </div>
        <div class="center-contents btn-container">
            <button class="iona-button dark" (click)="setStep(onboardingSteps.analyticsOptIn)">
                {{ 'screens.register.onBoarding.hardwarePresent.yesButton' | translate }}
            </button>
        </div>
        <div class="center-contents">
            <a (click)="hardwareNotReady()">{{ 'screens.register.onBoarding.hardwarePresent.noLink' | translate }}</a>
        </div>
    </section>


    <!---- ANALYTICS ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.analyticsOptIn">
        <h1 class="m-b-x">{{ 'screens.register.onBoarding.analyticsOptIn.heading' | translate }}</h1>

        <div class="permissions-wrap">
            <form class="login-form"
                  (submit)="checkOptInResponse(trackingCb.checked, optInCb.checked, dataOptInCb.checked)">
                <div class="permissions-list">

                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="tracking-checkbox">
                            <input #trackingCb type="checkbox" id="tracking-checkbox">
                            {{ 'ionaSpecific.onBoarding.allowTracking' | translate }}
                        </label>
                        <p>
                            {{ 'screens.register.onBoarding.analyticsOptIn.googleAnalytics.description' | translate }}
                        </p>
                    </div>

                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="optin-checkbox">
                            <input #optInCb type="checkbox" name="checkbox" id="optin-checkbox">
                            {{ 'screens.register.onBoarding.analyticsOptIn.onlineMode.label' | translate }}
                        </label>
                        <p>
                            {{
                                'screens.register.onBoarding.analyticsOptIn.onlineMode.description' | translate: {
                                    device: device === 'plug' && this.deviceTitle.includes('2.0')
                                        ? ('ionaSpecific.devices.box4' | translate)
                                        : device === 'plug'
                                            ? ('ionaSpecific.devices.plug3' | translate)
                                            : ('ionaSpecific.devices.box3' | translate)
                                }
                            }}
                        </p>
                    </div>


                    <div class="iona-checkbox-wrap">
                        <label class="iona-checkbox" for="data-checkbox">
                            <input #dataOptInCb type="checkbox" name="checkbox" id="data-checkbox">
                            {{ 'screens.register.onBoarding.analyticsOptIn.dataProcessing.label' | translate }}
                        </label>
                        <p>
                            {{ 'ionaSpecific.onBoarding.optInDataProcessing.description' | translate }}
                        </p>
                    </div>
                </div>
                <div class="permissions-btn-wrap">
                    <button class="iona-button dark" type="submit">{{ 'common.continue' | translate }}</button>
                </div>
            </form>
        </div>
    </section>

    <!---- BOX POWER CONNECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.powerConnect">
        <h1 class="m-b-x">
            {{ 'screens.register.onBoarding.powerConnect.heading' | translate: {device: isSmartBox2() ? getDeviceTitle() : 'Hardware'} }}
        </h1>
        <div class="device-video-container">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source
                    [src]="isSmartBox2() ? 'assets/media/onboarding/onboarding_connect_power_2.0.mp4' : 'assets/media/onboarding/onboarding_connect_power.mp4'"
                    type="video/mp4">
            </video>
        </div>
        <p>
            {{ 'screens.register.onBoarding.powerConnect.paragraph' | translate }}
        </p>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.box.lanConnect)">
                {{ 'common.iDid' | translate }}
            </button>
        </div>
    </section>

    <!---- BOX LAN CONNECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.lanConnect">
        <h1 class="m-b-x">{{ 'ionaSpecific.onBoarding.boxLanConnect.heading' | translate }}</h1>
        <div class="device-video-container">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/onboarding_connect_lan.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.box.macEntry)">
                {{ 'common.iDid' | translate }}
            </button>
        </div>
    </section>

    <!---- BOX MAC ENTRY ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.macEntry">
        <div class="top-logo center-contents"></div>
        <h1>{{ 'ionaSpecific.onBoarding.boxMacEntry.heading' | translate }}</h1>
        <h2>{{ 'ionaSpecific.onBoarding.boxMacEntry.subheading' | translate }}</h2>
        <form class="login-form m-t-x" (submit)="setFirstMACAddress(mac.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <input #mac class="iona-input" type="text" name="mac"
                           [textMask]="macAddressMask"
                           [(ngModel)]="firstMac" [ngModelOptions]="{standalone: true}"
                           [placeholder]="macPlaceholder">
                </div>
            </div>

            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit">{{ 'common.continue' | translate }}</button>
            </div>
        </form>
    </section>

    <!---- BOX MAC ENTRY 2 ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.macEntry2">
        <div class="top-logo center-contents">
        </div>
        <h1>{{ 'ionaSpecific.onBoarding.boxMacEntry2.heading' | translate }}</h1>
        <form class="login-form m-t-x" (submit)="registerDevice(mac2.value,true)">
            <div class="grow-space">
                <div class="btn-container">
                    <input #mac2 class="iona-input" type="text" name="mac"
                           [textMask]="macAddressMask"
                           [(ngModel)]="secondMac" [ngModelOptions]="{standalone: true}"
                           [placeholder]="macPlaceholder">
                </div>
            </div>

            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit">{{ 'common.continue' | translate }}</button>
            </div>
        </form>
    </section>

    <!---- BOX CONNECTION ---------------------------------------->
    <section class="connection white" *ngIf="currentStep === onboardingSteps.devices.box.connecting">
        <h1>{{ 'ionaSpecific.onBoarding.boxConnecting.heading' | translate }}</h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div
                        class="progress-label">{{ 'ionaSpecific.onBoarding.boxConnecting.progress.preparingBox' | translate }}
                    </div>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.boxConnecting.progress.searchingMeter' | translate }}
                    </div>
                </li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.boxConnecting.progress.connectingMeter' | translate }}
                    </div>
                </li>
            </ul>
        </div>
        <ng-container *ngIf="meterStatus > 0">
            <div class="last-growing">
                <button class="iona-button dark"
                        (click)="isERNAUser ? setStep(onboardingSteps.tariffEntry) :
                                   setStep(onboardingSteps.accountSetup)">
                    {{ 'common.goToDashboard' | translate }}
                </button>
            </div>
        </ng-container>
    </section>

    <!---- PLUG NOTE MAC ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.noteMac">
        <h1>
            {{ 'screens.register.onBoarding.plugNoteMac.heading' | translate:{device: isSmartBox2() ? ('ionaSpecific.devices.plug3' | translate) : ('ionaSpecific.devices.box4' | translate)} }}</h1>
        <div class="center-contents m-v-x">
            <img
                [src]="isSmartBox2() ? 'assets/img/onboarding/mac_box.png' : 'assets/img/onboarding/plug-mac.png'"
                alt="" [style.height]="isSmartBox2() ? '170px' : '400px'">
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.powerConnect)">
                {{ 'common.continue' | translate }}
            </button>

        </div>
    </section>

    <!---- PLUG POWER CONNECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.powerConnect">
        <h1>
            {{ 'screens.register.onBoarding.plugPowerConnect.heading' | translate:{device: isSmartBox2() ? getDeviceTitle() : 'Powerchecker'} }}
        </h1>
        <video width="100%" height="100%" autoplay muted loop>
            <source
                [src]="isSmartBox2() ? 'assets/media/onboarding/onboarding_connect_power_2.0.mp4' : 'assets/media/onboarding/onboarding_connect_power.mp4'"
                type="video/mp4">
        </video>
        <p>
            {{ 'screens.register.onBoarding.plugPowerConnect.instructions' | translate }}
        </p>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.appSelect)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!---- APP SELECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.appSelect">
        <h1>
            {{ 'screens.register.onBoarding.appSelect.heading' | translate:{device: getDeviceTitle()} }}
        </h1>
        <div class="center-contents m-v-x">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.wpsConnect)">
                {{ 'common.continue' | translate }}
            </button>

        </div>
        <div>
            <p>{{ 'ionaSpecific.onBoarding.appSelect.instructions' | translate }}</p>
            <div class="store-links m-b-l">
                <a target="_blank" class="center-contents ignore-border"
                   href='https://play.google.com/store/apps/details?id=com.iona_energy.android&hl=en'>
                    <img class="p-r-l" alt='Jetzt bei Google Play' src='assets/images/badges/googleplay.png'/>
                </a>
                <a target="_blank" class="center-contents ignore-border"
                   href="https://apps.apple.com/de/app/iona/id1434046824">
                    <img class="p-l-l" src="assets/images/badges/appstore.png" alt="Laden im AppStore">
                </a>
            </div>
        </div>

    </section>

    <!---- WPS CONNECT ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.wpsConnect">
        <h1 *ngIf="isSmartBox2()">{{ 'ionaSpecific.onBoarding.wpsConnect.headingSmartBox2' | translate }}</h1>
        <h1 *ngIf="!isSmartBox2()">{{ 'ionaSpecific.onBoarding.wpsConnect.headingOther' | translate }}</h1>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source [src]="isSmartBox2() ? 'assets/media/box_2.0.mp4' : 'assets/media/powerchecker-2.mp4'"
                        type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.wpsRouter)">
                {{ 'common.continue' | translate }}
            </button>

        </div>
    </section>

    <!---- WPS ROUTER ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.wpsRouter">
        <h1>{{ 'screens.register.onBoarding.wpsRouter.heading' | translate }}</h1>
        <p class="info-white">{{ 'screens.register.onBoarding.wpsRouter.info' | translate }}</p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>
                <source src="assets/media/wlan.mp4" type="video/mp4">
            </video>
        </div>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.wifiConnected)">
                {{ 'common.continue' | translate }}
            </button>

        </div>
    </section>

    <!---- WPS CONNECTED---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.wifiConnected">
        <h1>{{ 'screens.register.onBoarding.wifiConnected.heading' | translate:{deviceTitle: getDeviceTitle()} }}</h1>
        <div class="grow-space m-v-x">
            <img
                [src]="isSmartBox2() ? '/assets/images/box-onboarding-final-state.png' : '/assets/images/powerchecker-onboarding-final-state.png'"
                alt="Blau leuchtender Powerchecker" [style.height]="isSmartBox2() ? '300px' : '400px'">
        </div>
        <div class="center-contents btn-container">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.macEntry)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>

    <!---- PLUG MAC ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.macEntry">
        <div class="top-logo center-contents">
        </div>
        <h1>
            {{ 'screens.register.onBoarding.macEntry.heading' | translate: {deviceTitle: isSmartBox2() ? getDeviceTitle() : ('ionaSpecific.devices.plug3' | translate)} }}
        </h1>
        <h2>{{ 'screens.register.onBoarding.macEntry.subheading' | translate:{deviceTitle: getDeviceTitle()} }}</h2>
        <form class="login-form m-t-x"
              (ngSubmit)="setPlugMacAddress()"
              [formGroup]="deviceMacForm">
            <div class="grow-space">
                <div class="btn-container">
                    <input type="text" class="iona-input"
                           [formControl]="deviceMacForm.controls.mac"
                           [textMask]="macAddressMask"
                           [placeholder]="macPlaceholder">
                </div>
            </div>

            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit"
                        [disabled]="deviceMacForm.invalid">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>


    <!---- READER MAC ENTRY ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.findAndEnterORMacAddress">
        <h1>{{ 'screens.register.onBoarding.readerMacEntry.heading' | translate }}</h1>
        <p>
            {{ 'screens.register.onBoarding.readerMacEntry.description' | translate }}
        </p>
        <form class="login-form m-t-l"
              [formGroup]="readerMacForm"
              (ngSubmit)="setOpticalReaderMacAddress()">
            <div class="m-v-m">
                <div class="grow-space">
                    <div class="btn-container">
                        <input type="text" class="iona-input"
                               [formControl]="readerMacForm.controls.mac"
                               [textMask]="opticalReaderMacAddressMask"
                               [placeholder]="opticalReaderMacPlaceholder">
                    </div>
                </div>
            </div>
            <div class="grow-space p-t-x">
                <img src="/assets/img/onboarding/optical-reader-mac.png" width="100%">
            </div>
            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit"
                        [disabled]="readerMacForm.invalid">
                    {{ 'common.continue' | translate }}
                </button>
            </div>
        </form>
    </section>


    <!-- READER & PLUG POSITIONING -->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.positioningOR">
        <h1>{{ 'screens.register.onBoarding.positioningOR.heading' | translate }}</h1>
        <p>
            {{
                'ionaSpecific.onBoarding.positioningOR.descriptionPlug' | translate: {
                    device: isSmartBox2() ? 'der ' + getDeviceTitle() : ('ionaSpecific.devices.plug4' | translate),
                    device2: getDeviceTitle(),
                    device3: isSmartBox2() ? ('ionaSpecific.onBoarding.positioningOR.andInternet') : ''
                }
            }}
        </p>
        <div class="grow-space">
            <div class="center-contents p-a-x">
                <img
                    [src]="isSmartBox2() ? '/assets/img/onboarding/optical-reader-box-placement.png' : '/assets/img/onboarding/optical-reader-powerchecker-placement.png'"
                    [style.width]="isSmartBox2() ? '155%' : '130%'">
            </div>
        </div>
        <div class="center-contents btn-container">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.preparingPlug)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>


    <!-- PRECONDITION STATE -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.preparingPlug">
        <h1>{{ 'screens.register.onBoarding.preparingPlug.heading' | translate }}</h1>
        <p>
            {{
                isSmartBox2()
                    ? ('ionaSpecific.onBoarding.preparingPlug.descriptionSmartBox' | translate)
                    : ('ionaSpecific.onBoarding.preparingPlug.descriptionPlug' | translate)
            }}
            {{
                !isSmartBox2()
                    ? ('ionaSpecific.onBoarding.preparingPlug.descriptionPowerChecker' | translate)
                    : ''
            }}
        </p>
        <div class="flex-col grow-space">
            <div class="center-contents p-a-x">
                <img
                    [src]="isSmartBox2() ? '/assets/img/onboarding/box-ready.png' : '/assets/img/onboarding/powerchecker-ready.webp'"
                    [style.width]="isSmartBox2() ? '133%' : '30%'">
            </div>
            <div class="flex grow-space center-contents">
                <ng-container *ngIf="!registrationOnboardSuccessful">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                </ng-container>
                <ng-container *ngIf="registrationOnboardSuccessful">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                </ng-container>
            </div>
        </div>
        <div class="center-contents btn-container">
            <button class="iona-button dark"
                    [disabled]="!registrationOnboardSuccessful"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.powerReaderAndStartCommission)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 3 -->
    <!----------------------------------------------------------------------------------------------------------------->

    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.powerReaderAndStartCommission">
        <h1>
            {{ 'screens.register.onBoarding.startCommissioning.headingPlug' | translate: {device: getDeviceTitle()} }}
        </h1>
        <p *ngIf='commissionStatus !== "initial"'>
            {{ 'screens.register.onBoarding.startCommissioning.description1' | translate: {device: getDeviceTitle()} }}
            <b> {{ 'screens.register.onBoarding.startCommissioning.description2' | translate }} </b>
            {{ 'screens.register.onBoarding.startCommissioning.description3' | translate: {device: getDeviceTitle()} }}
        </p>
        <p *ngIf='commissionStatus === "initial"'>
            {{ 'ionaSpecific.onBoarding.startCommissioning.description' | translate: {device: getDeviceTitle()} }}
        </p>
        <div class="flex-col center-contents">
            <ng-container [ngSwitch]="commissionStatus">
                <ng-container *ngSwitchCase="'loading'">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                    <p class="m-t-l center-text white">
                        {{ 'ionaSpecific.onBoarding.startCommissioning.description2' | translate }}
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'success'">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                    <p class="m-t-l center-text white">
                        {{ 'ionaSpecific.onBoarding.startCommissioning.description3' | translate }}
                    </p>
                    <div class="center-contents btn-container">
                        <button class="iona-button dark"
                                [disabled]="false"
                                (click)="startFirmwareUpdate()">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                    <img src="/assets/img/onboarding/loading-info.webp" width="65px" height="65px">
                    <p class="m-t-l center-text white">
                        {{ 'common.errors.connectionFailed' | translate }}
                    </p>
                    <div class="center-contents btn-container">
                        <button class="iona-button dark commissionErrorButtons" (click)="resetMacAddressEntry()"
                                [disabled]="false">
                            {{ 'common.errors.retryButton' | translate }}
                        </button>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="iona-button dark commissionErrorButtons" [disabled]="false"
                                (click)="openSupportDialog()">
                            {{ 'common.supportButton' | translate }}
                        </button>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="iona-button dark commissionErrorButtons" [disabled]="true">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'initial'">
                    <div class="m-v-x">
                        <video width="100%" height="100%" autoplay muted loop>-->
                            <source src="assets/media/onboarding/power-and-button.mp4" type="video/mp4">
                        </video>
                    </div>
                    <div class="center-contents btn-container">
                        <button class="iona-button dark"
                                (click)="startCommission()">
                            {{ 'common.continue' | translate }}
                        </button>
                    </div>
                </ng-container>
            </ng-container>
        </div>
    </section>


    <!-- INFO PROCESS DURATION -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.infoProcessDuration">
        <h1>{{ 'screens.register.onBoarding.afterUpdatePowerCycle.heading' | translate }}</h1>
        <p>
            {{ 'screens.register.onBoarding.afterUpdatePowerCycle.description' | translate: {deviceTitle: getDeviceTitle()} }}
        </p>
        <div class="flex-col grow-space center-contents">
            <ng-container [ngSwitch]="firmwareUpdateStatus">
                <ng-container *ngSwitchCase="'loading'">
                    <ng-lottie [options]="loadingLottieConfig" width="150px" height="150px"></ng-lottie>
                    <p class="m-t-l center-text">
                        {{ 'screens.register.onBoarding.afterUpdatePowerCycle.loadingText' | translate }}
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'uptodate'">
                    <img src="/assets/img/onboarding/loading-done.webp" width="65px" height="65px">
                    <p class="m-t-l center-text">
                        {{ 'screens.register.onBoarding.afterUpdatePowerCycle.uptodateText' | translate }}
                    </p>
                </ng-container>
                <ng-container *ngSwitchCase="'error'">
                    <img src="/assets/img/onboarding/loading-info.webp" width="65px" height="65px">
                    <p class="m-t-l center-text">
                        {{ 'screens.register.onBoarding.afterUpdatePowerCycle.errorText' | translate }}
                    </p>
                    <p>
                        {{ 'screens.register.onBoarding.afterUpdatePowerCycle.errorAdditionalText' | translate }}
                    </p>
                </ng-container>
            </ng-container>
            <ng-container *ngIf="firmwareIsUpdating">
                {{ 'screens.register.onBoarding.afterUpdatePowerCycle.updatingText' | translate }}
            </ng-container>
        </div>
        <div class="center-contents btn-container">
            <button class="iona-button dark"
                    [disabled]="!(firmwareUpdateStatus === 'uptodate' || firmwareUpdateStatus === 'error')"
                    (click)="setStep(onboardingSteps.opticalReader.deviceSelection)">
                {{ 'common.continue' | translate }}
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 1 -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep1">
        <h1>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep1.heading' | translate }}</h1>
        <p>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep1.paragraph' | translate }}</p>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/optical_reader/erna-04.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep2)">
                {{ 'screens.register.onBoarding.orSetup.readerInstallationStep1.button' | translate }}
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 2 -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep2">
        <h1>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep2.heading' | translate }}</h1>
        <p>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep2.paragraph' | translate }}</p>


        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/optical_reader/erna-05.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep3)">
                {{ 'screens.register.onBoarding.orSetup.readerInstallationStep2.button' | translate }}
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 3 -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep3">
        <h1>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep3.heading' | translate }}</h1>
        <p>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep3.paragraph' | translate }}</p>
        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/powercycle.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep4)">
                {{ 'screens.register.onBoarding.orSetup.readerInstallationStep3.button' | translate }}
            </button>
        </div>
    </section>


    <!-- OPTICAL READER INSTALLATION STEP 4 -->
    <!----------------------------------------------------------------------------------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.plug.orSetup.readerInstallationStep4">
        <h1>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep4.heading' | translate }}</h1>
        <p>{{ 'screens.register.onBoarding.orSetup.readerInstallationStep4.paragraph' | translate }}</p>

        <div class="m-v-x">
            <video width="100%" height="100%" autoplay muted loop>-->
                <source src="assets/media/onboarding/optical_reader/erna-07.mp4" type="video/mp4">
            </video>
        </div>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="afterOrOnboardingComplete()">
                {{ 'screens.register.onBoarding.orSetup.readerInstallationStep4.button' | translate }}
            </button>
        </div>
    </section>


    <!---- PLUG CONNECTION ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.connecting"
             class="white connection">
        <h1>
            {{ 'screens.register.onBoarding.orSetup.plug.connecting.heading' | translate:{deviceTitle: getDeviceTitle()} }}
        </h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connecting.steps.prepare' | translate:{deviceTitle: getDeviceTitle()} }}
                    </div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connecting.steps.search' | translate }}
                    </div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connecting.steps.connect' | translate }}
                    </div>
                </li>
                <li class="progress-label-sub"></li>
                <ng-container *ngIf="isEnviamUser">
                    <li [ngClass]="{'finished': (meterStatus > 3), 'progress': (meterStatus == 3)}">
                        <div class="progress-indicator" [ngClass]="{done: meterStatus > 3}"></div>
                        <div class="progress-label">
                            {{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.search' | translate }}
                        </div>
                    </li>
                    <li class="progress-label-sub"></li>
                </ng-container>
            </ul>
        </div>
        <div class="last-growing">
            <ng-container *ngIf="meterStatus > 0">
                <button class="iona-button dark" type="submit" (click)="afterConnectionFinished()">
                    {{ 'common.goToDashboard' | translate }}
                </button>
            </ng-container>
        </div>
    </section>

    <!---- PLUG CONNECTION ---------------------------------------->
    <section *ngIf="currentStep === onboardingSteps.devices.plug.connectingOR" class="white connection">
        <h1>
            {{ 'screens.register.onBoarding.orSetup.plug.connectingOR.heading' | translate:{deviceTitle: getDeviceTitle()} }}
        </h1>
        <div>
            <ng-lottie class="loading-smartmeter" height="108px" [options]="lottieConfig2"
                       (animationCreated)="handleAnimation($event)"></ng-lottie>
            <ul class="connection-progress">
                <li [ngClass]="{'finished': (meterStatus > 0), 'progress': (meterStatus <= 0)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 0}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.prepare' | translate:{deviceTitle: getDeviceTitle()} }}
                    </div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 1), 'progress': (meterStatus == 1)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 1}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.search' | translate }}
                    </div>
                </li>
                <li class="progress-label-sub">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.pressButton' | translate }}</li>
                <li [ngClass]="{'finished': (meterStatus > 2), 'progress': (meterStatus == 2)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 2}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.connect' | translate }}
                    </div>
                </li>
                <li class="progress-label-sub"></li>
                <li [ngClass]="{'finished': (meterStatus > 3), 'progress': (meterStatus == 3)}">
                    <div class="progress-indicator" [ngClass]="{done: meterStatus > 3}"></div>
                    <div
                        class="progress-label">{{ 'screens.register.onBoarding.orSetup.plug.connectingOR.steps.unlock' | translate }}
                    </div>
                </li>
                <li class="progress-label-sub">
                    {{ meterStatus === 3 ? ('screens.register.onBoarding.orSetup.plug.connectingOR.steps.waiting' | translate) : '' }}
                </li>
            </ul>
            <ng-container *ngIf="meterStatus>=3">
                <div>
                    <button class="iona-button dark" type="submit"
                            (click)="setStep(onboardingSteps.accountSetup)">
                        {{ 'common.goToDashboard' | translate }}
                    </button>
                </div>
            </ng-container>
        </div>
    </section>

    <!---- ACCOUNT SETUP ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.accountSetup">
        <div class="top-logo center-contents">
        </div>
        <h1>{{ 'screens.register.onBoarding.accountSetup.welcome' | translate }}</h1>
        <h2>
            {{ 'screens.register.onBoarding.accountSetup.introduction' | translate }}
        </h2>
        <div class="center-contents btn-container">
            <button class="iona-button dark" type="submit" routerLink="/mein-haushalt">
                {{ 'common.goToDashboard' | translate }}
            </button>
        </div>
    </section>

    <!---- BOX CONNECTION ERROR ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.connectionError">
        <div class="top-logo center-contents">
        </div>
        <h1>
            {{
                'ionaSpecific.onBoarding.boxConnectionError.title' | translate: {
                    device: device == 'plug' ? 'den' : 'die',
                    device2: device == 'plug' ? 'PowerChecker' : 'iONA Box',
                    device3: isSmartBox2() && ' 2.0 '
                }
            }}
        </h1>
        <h2>{{ 'screens.register.onBoarding.boxConnectionError.subtitle' | translate }}</h2>
        <div class="last-growing">
            <div class="center-contents">
                <button class="iona-button dark" type="submit"
                        (click)="device == 'plug' ?
                         setStep(onboardingSteps.devices.plug.macEntry) :
                          setStep(onboardingSteps.devices.box.macEntry)">
                    {{ 'screens.register.onBoarding.boxNotFoundError.buttonMacEntry' | translate }}
                </button>
            </div>
        </div>
    </section>

    <!---- BOX CONNECTION ERROR ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.devices.box.notFoundError">
        <div class="top-logo center-contents">
            <h1>iONA</h1>
        </div>
        <h1>{{
                'ionaSpecific.onBoarding.boxConnectionError.title' | translate: {
                    device: device == 'plug' ? 'den' : 'die',
                    device2: device == 'plug' ? 'PowerChecker' : 'iONA Box',
                    device3: isSmartBox2() && ' 2.0 '
                }
            }}
        </h1>
        <h2>{{ 'screens.register.onBoarding.boxNotFoundError.subtitle' | translate }}</h2>
        <div class="last-growing">
            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit"
                        (click)="device == 'plug' ?
                          setStep(onboardingSteps.devices.plug.macEntry) :
                          setStep(onboardingSteps.devices.box.macEntry)">
                    {{ 'screens.register.onBoarding.boxNotFoundError.buttonMacEntry' | translate }}
                </button>
            </div>
            <div class="center-contents">
                <a href="javascript:;" target="_self" (click)="openSupportDialog()"
                   angulartics2On="click" angularticsCategory="Onboarding"
                   angularticsAction="onboarding_profile_cancel">
                    {{ 'screens.register.onBoarding.boxNotFoundError.supportLink' | translate }}
                </a>
            </div>
        </div>
    </section>

    <!---- SOMETHING FAILED ---------------------------------------->
    <section class="white" *ngIf="currentStep === onboardingSteps.detailFailed">
        <div class="top-logo center-contents"></div>
        <h1>{{ statusError.title }}</h1>
        <h2>{{ statusError.text }} </h2>
        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="retryMeterStatus()">
                {{ 'screens.register.onBoarding.detailFailed.retry' | translate }}
            </button>
        </div>
    </section>

    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.deviceSelection">
        <h1>{{ 'screens.register.onBoarding.deviceSelection.heading' | translate }}</h1>
        <p class="white">
            {{
                'ionaSpecific.onBoarding.deviceSelection.paragraph' | translate: {
                    device: isSmartBox2() ? getDeviceTitle() : 'PowerCheckers',
                    device2: getDeviceTitle()
                }
            }}

        </p>
        <div class="flow-image-container">
            <div>
                <img src="assets/img/graphics/optical-reader-flow.png" alt="" width="100%">
            </div>
        </div>

        <p class="white">
            {{ 'screens.register.onBoarding.startCommissioning.subText' | translate: {deviceTitle: getDeviceTitle()} }}
        </p>

        <div class="last-growing">
            <button class="iona-button dark"
                    (click)="setStep(onboardingSteps.devices.plug.orSetup.readerInstallationStep1)">
                {{ 'screens.register.onBoarding.deviceSelection.button' | translate }}
            </button>
        </div>
    </section>


    <!-- OPTICAL READER PIN ENTRY -->
    <section class="white" *ngIf="currentStep === onboardingSteps.opticalReader.pinEntry">
        <h1>{{ 'screens.register.onBoarding.opticalReader.pinEntry.title' | translate }}</h1>
        <h2>{{ 'screens.register.onBoarding.opticalReader.pinEntry.subtitle' | translate }}</h2>
        <form class="login-form m-t-x" (ngSubmit)="setPIN(pin.value)">
            <div class="grow-space">
                <div class="btn-container">
                    <input #pin type="text" name="pin" class="iona-input" [placeholder]="1234" [attr.maxlength]="4">
                </div>
            </div>
            <div class="center-contents btn-container">
                <button class="iona-button dark" type="submit">
                    {{ 'screens.register.onBoarding.opticalReader.pinEntry.buttonAccept' | translate }}
                </button>
            </div>
            <div class="center-contents">
                <a class="" (click)="setPIN(null)">
                    {{ 'screens.register.onBoarding.opticalReader.pinEntry.linkSkip' | translate }}
                </a>
            </div>
        </form>

    </section>

    <!-- TARIFF ENTRY -->
    <section class="white" *ngIf="currentStep === onboardingSteps.tariffEntry">
        <h1>{{ 'ionaSpecific.onBoarding.tariffEntry.title' | translate }}</h1>
        <form class="tariff-entry-form" #tariffForm="ngForm" (ngSubmit)="saveProviderInfo()"
              (change)="onProviderFormChange($event)">
            <div class="form">
                <input name="name" type="text" class="iona-input"
                       placeholder="{{'popovers.tariffDataEntry.namePlaceholder' | translate}}"
                       [(ngModel)]="tariffInfo.name">
            </div>
            <div class="formItem">
                <input #dateinput name="dateStart" type="text"
                       placeholder="{{'ionaSpecific.onBoarding.tariffEntry.placeholder' | translate}}"
                       class="iona-input formText"
                       [min]="'2018-01-01'"
                       [max]="moment().format('MM-DD-YYYY')"
                       (focus)="dateinput.type = 'date'"
                       [(ngModel)]="tariffInfo.dateStart">
            </div>
            <div class="formItem">
                <input name="basePrice" type="number" class="iona-input"
                       placeholder="{{'popovers.tariffDataEntry.basePricePlaceholder' | translate}}"
                       [(ngModel)]="tariffInfo.basePrice">
            </div>
            <div class="formItem">
                <input name="workPrice" type="number" class="iona-input"
                       placeholder="{{'ionaSpecific.onBoarding.tariffEntry.placeholder2' | translate}}"
                       [(ngModel)]="tariffInfo.workPrice">
            </div>

            <div>
                <div>
                    <button type="submit" class="iona-button dark"
                            [disabled]="saveTariffDisabled">{{ 'popovers.tariffDataEntry.saveButton' | translate }}
                    </button>
                    <div class="skip-container">
                        <a href="javascript:;" type="button" (click)="skipTariffs()"
                           title="Überspringen">{{ 'common.continue' | translate }}</a>
                    </div>
                </div>
            </div>
        </form>
    </section>
</main>
