<mat-dialog-content class="modal-content" [ngClass]="{'more-padding': morePadding}">
    <button *ngIf="showCloseButton" class="close-button" (click)="closeModal()">
        <img src="assets/img/icons/close.png" alt="Close" width="30px">
    </button>
    <div *ngIf="imageUrl" class="icon-container">
        <img [src]="imageUrl" width="85px" alt="">
    </div>
    <div *ngIf="title" class="title-container">
        <h2 [ngClass]="{'align-left': alignLeft}" [ngStyle]="{'font-size': titleFontSize}">{{ title }}</h2>
    </div>
    <div [ngClass]="{'text-container-with-image': imageUrl, 'text-container': !imageUrl}">
        <p *ngIf="!isHtmlText" [ngClass]="{'align-left': alignLeft}" [ngStyle]="{'font-size': fontSize}">{{ modalText }}</p>
        <p *ngIf="isHtmlText" [innerHTML]="modalText" [ngClass]="{'align-left': alignLeft}" [ngStyle]="{'font-size': fontSize}"></p>
    </div>
    <button class="iona-button orange" (click)="closeModal()">
        {{ buttonText }}
    </button>
</mat-dialog-content>
