<div class="overlay">
    <div class="overlay-container-cdk">

        <div class="header">
            <div class="grow-space">
                <h2 class="m-a-n">{{ 'popovers.tariffHistory.title' | translate }}</h2>
            </div>
            <button class="iona-icon-only-button close" (click)="close(null)"></button>

        </div>

        <div class="growth-wrapper">
            <ul class="history-list">
                <li *ngFor="let t of tariffs; let i = index"
                    class="row">
                    <div class="grow-space">
                        <h3>{{ t.name }}</h3>
                        <div class="tariff-row">
                            <div>
                                <p>{{'popovers.tariffHistory.basePrice' | translate}}:</p>
                                <p>{{'popovers.tariffHistory.workPrice' | translate}}:</p>
                                <p>{{'popovers.tariffHistory.from' | translate}}:</p>
                                <p>{{'popovers.tariffHistory.to' | translate}}:</p>
                            </div>
                            <div>
                                <p>{{ t.basePrice + ' € ' }}{{ 'popovers.tariffHistory.perYear' | translate }}</p>
                                <p>{{ determineWorkingPrice(t.workPrice) }}</p>
                                <p>{{ determineStartDate(t) }}</p>
                                <p>{{ determineEndDate(t, i) }}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button class="iona-icon-only-button round edit dark" (click)="openEditOverlay(t, i)"></button>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</div>
