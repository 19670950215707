import {Component, OnDestroy, OnInit} from '@angular/core';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {BasePopover} from '../../../classes/BasePopover';
import * as moment from 'moment';
import {
    TodayDataProviderService
} from '../../../services/data-provider/today-data-provider.service';
import {ViewState} from '../../../shared/enums/view-state.enum';
import {combineLatest, share} from 'rxjs';
import { InitializationService} from '../../../services/initialization.service';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';


@Component({
    selector: 'app-today-details',
    templateUrl: './today-details.component.html',
    styleUrls: ['./today-details.component.scss'],
    viewProviders: []
})

export class TodayDetailsComponent extends BasePopover implements OnInit, OnDestroy {
    LABELS;
    TEXTS;
    protected readonly ViewState = ViewState;

    currentComparisonDate = moment().subtract(7, 'days').toDate();

    infoVisible = false;

    dateChanged = false;

    isVisionUser = true;

    isAccordionOpen: { [key: string]: boolean } = {};

    todayTileData$ = this.todayDataProviderService.todayTileData$.pipe(share());
    combinedData$ = combineLatest([
        this.todayDataProviderService.todayDetailData$,
        this.todayDataProviderService.todayDetailFullData$
    ]).pipe(
        map(([detail, full]) => ({ detail, full }))
    );


    constructor(
        protected popoverRef: PopoverRef,
        private todayDataProviderService: TodayDataProviderService,
        private initialization: InitializationService,
        private translate: TranslateService
    ) {
        super(popoverRef);
    }


    ngOnInit() {
        this.todayDataProviderService.getTodayComparisonForSetComparisonDate(this.currentComparisonDate);
        this.checkIsVisionUser();
        this.translate.get('screens.dashboard.todayDetail.labels').subscribe((labels: any) => {
            this.LABELS = labels;
        });

        this.translate.get('screens.dashboard.todayDetail.texts').subscribe((texts: any) => {
            this.TEXTS = texts;
        });
    }

    toggleAccordion(panel: string): void {
        this.isAccordionOpen[panel] = !this.isAccordionOpen[panel];
    }


    checkIsVisionUser(): void {
        this.initialization.getWithCache().subscribe((data) => {
            if ('product_name' in data){
                this.isVisionUser = data.product_name.toLowerCase().includes('vision');
            }
        });
    }


    ngOnDestroy() {
        super.ngOnDestroy();
        this.todayDataProviderService.killDetailSub();
    }


    onComparisonDateChange(selectedValue: Date) {
        this.currentComparisonDate = selectedValue;
        this.dateChanged = true;
        this.todayDataProviderService.getTodayComparisonForSetComparisonDate(selectedValue);
    }
}

