import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {HttpClient} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {constants} from '../shared/constants/constants';
import {BaseService} from './base-service';
import {ApplicationService} from './application.service';
import {UserService} from './user.service';
import {HouseholdProfile} from '../shared/interfaces/profile-attributes.interfaces';


@Injectable({
    providedIn: 'root'
})
export class ProfileService extends BaseService {

    private cachedAttributes = null;


    constructor(protected http: HttpClient,
                protected auth: ApiService,
                protected user: UserService,
                private application: ApplicationService) {
        super(http, auth, user);
    }


    destroy(): void {
        super.destroy();

    }


    getAttributes(): Observable<HouseholdProfile> {
        let url = this.API_BASE_URL + constants.api.routes.profile.attributes;
        if (this.application.isDemoMode()) {
            url = `assets/data/demo/${constants.demo.files.profileAttributes}.json`;
        }
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    getAttributesWithCache(): Observable<HouseholdProfile> {
        if (this.cachedAttributes) {
            return of(this.cachedAttributes);
        }
        return this.getAttributes().pipe(
            map(res => {
                this.cachedAttributes = res;
                return res;
            })
        );
    }


    setAttributes(config: any): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.profile.attributes;
        this.clearCachedAttributes();
        return this.http.post(url, config).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    getContract(accountRewrite = false): Observable<any> {
        let url = this.API_BASE_URL + constants.api.routes.profile.this;
        if (accountRewrite) {
            url = this.ACCOUNT_REWRITE_BASE_URL + constants.api.routes.profile.this;
        }
        if (this.application.isDemoMode()) {
            url = 'assets/data/demo/' + constants.demo.files.profile + '.json';
        }
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }


    private clearCachedAttributes(): void {
        this.cachedAttributes = null;
    }

    getVoucher(): Observable<any> {
        const url = this.API_BASE_URL + constants.api.routes.installations;
        return this.http.get(url).pipe(
            map((res: { status: string, data: any }) => this.mapDefault(res)),
            catchError((error: any) => this.handleError(error))
        );
    }
}
