import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from './user.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    public selectedLanguage = 'de';
    private readonly STORAGE_KEY = 'selected_language';

    constructor(private translate: TranslateService, private userService: UserService) {
        this.selectedLanguage = localStorage.getItem(this.STORAGE_KEY) || 'de';
        this.initTranslate();
    }

    initTranslate(): void {
        this.translate.setDefaultLang('de');
        this.translate.use(this.selectedLanguage);
    }

    getLanguage(): string {
        return this.selectedLanguage;
    }

    setLanguage(language: string): void {
        this.selectedLanguage = language;
        localStorage.setItem(this.STORAGE_KEY, language);
        this.translate.use(language);
        this.userService.removeActiveUserTiles();
    }
}
