/**
 * Contains general application-wide constants.
 */

/**
 * Constants
 */
export const constants = {
    api: {
        urls: {
            auth: 'https://live.innogy.2ndlab.de/auth',
            auth_wla: 'https://iona-dev.wla-backend.de/auth',
            base: 'https://api.n2g-iona.net/v2/'
        },
        routes: {
            instantaneous: 'instantaneous',
            instantaneousPower: 'instantaneous/power',
            phase: 'instantaneous/phases',
            homeState: {
                current: 'home_state/current',
                config: 'home_state/config'
            },
            power: 'power',
            installations: 'installations',
            disaggregation:  'disaggregation',
            electricity: {
                consumption: {
                    this: 'electricity/consumption',
                    compare: 'electricity/consumption/compare',
                    compareMonth: 'electricity/consumption/compare/month',
                },
                bill: {
                    prediction: 'electricity/bill/prediction'
                }
            },
            feedin: {
                electricity: 'feedin/electricity'
            },
            consumption: {
                electricity: {
                    appliances: {
                        months: 'consumption/electricity/appliances/months'
                    },
                    months: 'consumption/electricity/months',
                },
            },
            initialization: 'initialisation',
            powerThresholds: 'power-thresholds/config',
            profile: {
                this: 'profile',
                attributes: 'profile/attributes'
            },
            registration: {
                email: 'registration/email',
                setPassword: 'registration/setpassword',
                resetPassword: 'registration/resetpassword',
                voucher: 'registration/voucher',
                noGateway: 'registration/no-gateway',
                onboard: 'registration/onboard',
                optIn: 'registration/opt-in',
                online: 'registration/online',
                model: 'registration/model'
            },
            meter: {
                status: 'meter/status',
                connect: 'meter/connect',
                info: 'meter/info',
            },
            nilm: {
                status: 'nilm/status',
                retraining: 'nilm/retraining',
            },
            plug: {
                relay: 'plug/relay'
            },
            configuration: {
                dashboard: 'configuration/dashboard'
            },
            iona: {
                happyHour: {
                    this: 'iona/happyhour',
                    participation: 'iona/happyhour/participation',
                    schedule: 'iona/happyhour/schedule',
                    consumption: {
                        electricity: {
                            days: 'iona/happyhour/consumption/electricity/days',
                            weeks: 'iona/happyhour/consumption/electricity/weeks',
                            months: 'iona/happyhour/consumption/electricity/months',
                            years: 'iona/happyhour/consumption/electricity/years',
                        }
                    }
                },
                usergroups: {
                    this: 'iona/usergroups'
                }
            },
            smartbridge: {
                reportMode: {
                    realTime: 'smartbridge/report-mode/real-time',
                    settings: 'smartbridge/report-mode/settings',
                }
            },
            meterreader: {
                this: 'meterreader',
                status: 'meterreader/status',
                commission: 'meterreader/commission',
            },
            firmware: {
                this: 'firmware/approve-immediate-update'
            },
            mfa: {
                enable: 'mfa/enable',
                disable: 'mfa/disable',
                status: 'mfa/status',
                verifySoftwareToken: 'mfa/verify-software-token',
                token: 'mfa/token'
            },
            userGroup: {
                groups: 'user-group-management/groups'
            },
            optin: {
                analytics: 'optin/ANALYTICS',
            }
        },
    },
    assets: {
        demo: 'assets/data/demo/'
    },
    demo: {
        files: {
            billPrediction: 'bill_prediction',
            billPredictionTest: 'bill_prediction_test',
            consumption: 'consumption',
            consumptionDays: 'consumption_days',
            consumptionHours: 'consumption_hours',
            consumptionHoursLastWeek: 'consumption_hours_last_week',
            consumptionMonths: 'consumption_months',
            disagregationHistory: 'disagregation_history',
            initialization: 'initialisation',
            initializationTest: 'initialisation_test',
            homeStateCurrent: 'home_state_current',
            homeStateConfig: 'home_state_config',
            nilmStatus: 'nilm_status',
            nilmStatusTest: 'nilm_status_test',
            power10Seconds: 'power_10_seconds',
            power15Minutes: 'power_15_minutes',
            power1Minute: 'power_1_minute',
            power5Seconds: 'power_5_seconds',
            power5SecondsNegative: 'power_5_seconds_negative',
            power5SecondsNegativeAlternating: 'power_5_seconds_negative_alternating',
            power5SecondsLast10Minutes: 'power_5_seconds_last_10_minutes',
            profile: 'profile',
            profileAttributes: 'profile_attributes',
            instantaneous: 'instantaneous',
            instantaneousPower: 'instantaneous_power',
            instantaneous_Phases: 'instantaneous_phases',
            instantaneousPhasesHistory: 'instantaneous_phases_history',
            meterStatus: 'meter_status',
            meterInfo: 'meter_info',
            registrationOnline: 'registration_online',
            registrationOnlineTest: 'registration_online_test',
            registrationModel: 'registration_model',
            registrationModelTest: 'registration_model_test',
            plugRelay: 'plug_relay',
            plugRelayTest: 'plug_relay_test',
            appliances: 'appliances',
            happyHourParticipation: 'hh_participation',
            happyHourSchedule: 'hh_schedule',
            benchmark: {
                cluster: {
                    currentYear: 'benchmark_cluster_current_year',
                    lastYear: 'benchmark_cluster_last_year',
                    lastMonth: 'benchmark_cluster_last_month',
                },
                ranking: {
                    currentYear: 'benchmark_ranking_current_year',
                    lastYear: 'benchmark_ranking_last_year',
                    lastMonth: 'benchmark_ranking_last_month',
                },
                deviceCategories: {
                    currentYear: 'benchmark_device_categories_current_year',
                    lastYear: 'benchmark_device_categories_last_year',
                    lastMonth: 'benchmark_device_categories_last_month',
                }
            },
        }
    },
    application: {
        states: {
            none: 'none',
            demo: 'demo',
            live: 'live'
        },
        devices: {
            plug: 'IONA4911',
            plug_optical: 'SPLR4911',
            smart_box: 'SBLR4910',
            smart_box2: 'SBLR4912',
            box: 'IONA4910',
        }
    }
};


export const tileIds = {
    live: 'live',
    today: 'today',
    comparison: 'compare',
    appliances: 'appliances',
    meter: 'meter',
    finance: 'finance',
    consumptionAlert: 'consumption-alert',
    phaseChecker: 'phase-checker',
    powerChecker: 'power-checker'
};

export const ernaProviders =
    ['avacon', 'schleswig-holstein netz', 'bayernwerk netz', 'e.dis'];

export const validVoucherPrefixes =
    ['igy', 'eon', 'edg', 'opto'];
