import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as moment from 'moment';


@Component({
    selector: 'app-date-picker-button',
    templateUrl: './date-picker-button.component.html',
    styleUrls: ['./date-picker-button.component.scss']
})
export class DatePickerButtonComponent implements OnInit {

    private datePickerDateFormat = 'MM.DD.YYYY';
    private displayDateFormat = 'DD.MM.YYYY';

    @Input() currentDate = new Date();
    @Input() minDate: string;
    @Input() hasIcon = true;
    @Input() alternativeClass = '';
    @Input() placeholder: string | null = null;
    @Input() dateDisplayDisabled = false;
    @Input() showCalendarIcon = false;

    @Output() dateChanged = new EventEmitter<Date>();

    @ViewChild('label', {static: true}) labelElement: ElementRef<HTMLLabelElement>;
    @ViewChild('dateSelect', {static: true}) inputElement: ElementRef<any>;

    maxDate: string = moment().format(this.datePickerDateFormat);


    constructor() {
    }


    ngOnInit() {
    }


    onDateChange(event): void {
        const d = new Date(event);
        this.currentDate = d;
        this.dateChanged.emit(d);
    }


    determineMinDate(): string {
        if (this.minDate) {
            return moment(this.minDate).format(this.datePickerDateFormat);
        }
        return '2018-01-01';
    }


    determineLabelContents(): string {
        if (this.dateDisplayDisabled) {
            return this.placeholder;
        }
        if (this.currentDate) {
            return moment(this.currentDate).format(this.displayDateFormat);
        }
        return this.placeholder;
    }


    onButtonClicked(): void {
        this.inputElement.nativeElement.showPicker();
    }


    getCurrentDateFormatted(): string {
        return moment(this.currentDate).format(this.displayDateFormat);
    }


    formatDateForInput(): string {
        return moment(this.currentDate).format(this.datePickerDateFormat);
    }
}
