import {
    BinaryConfirmPopoverComponent
} from './binary-confirm-popover/binary-confirm-popover.component';
import {ValueEntryPopoverComponent} from './value-entry-component/value-entry-popover.component';
import {UpdatePopoverComponent} from './update-popover/update-popover.component';
import {MfaCodePopoverComponent} from './mfa/mfa-code-popover/mfa-code-popover.component';
import {
    MfaCodeEntryPopoverComponent
} from './mfa/mfa-code-entry-popover/mfa-code-entry-popover.component';
import {MfaLoginPopoverComponent} from './mfa/mfa-login-popover/mfa-login-popover.component';
import {InfoPopoverComponent} from './info-popover/info-popover.component';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class PopoverConfigService {
    private translations: { [key: string]: string } = {};

    constructor(private translate: TranslateService) {
        this.initializeTranslations();
        this.translate.onLangChange.subscribe(() => {
            this.initializeTranslations();
        });
    }

    private initializeTranslations() {
        const keys = [
            'common.logout',
            'popovers.logout.text',
            'popovers.logout.positive',
            'popovers.logout.negative',
            'popovers.removeTile.title',
            'popovers.removeTile.text',
            'popovers.removeTile.positive',
            'popovers.removeTile.negative',
            'popovers.onboardingNoContact.title',
            'popovers.onboardingNoContact.text',
            'popovers.onboardingNoContact.positive',
            'popovers.onboardingWrongSerial.title',
            'popovers.onboardingWrongSerial.text',
            'popovers.onboardingWrongSerial.positive',
            'popovers.pinFailed.title',
            'popovers.pinFailed.text',
            'popovers.pinFailed.positive',
            'popovers.pinFailed.negative',
            'popovers.pinFailed.infoLink',
            'popovers.manualPinEntry.title',
            'popovers.manualPinEntry.text',
            'popovers.manualPinEntry.positive',
            'popovers.manualPinEntry.infoLink',
            'popovers.pinEntry.title',
            'popovers.pinEntry.placeholder',
            'popovers.pinEntry.text',
            'popovers.pinEntry.positive',
            'popovers.manualPinEntryInfo.title',
            'popovers.manualPinEntryInfo.text',
            'popovers.manualPinEntryInfo.positive',
            'popovers.manualPinEntryInfo.infoLink',
            'popovers.firmwareUpdateAvailable.title',
            'popovers.firmwareUpdateAvailable.text',
            'popovers.firmwareUpdateAvailable.positive',
            'common.later',
            'popovers.radioLinkLost.title',
            'popovers.radioLinkLost.text',
            'common.info.okay',
            'popovers.enterMeterValue.title',
            'popovers.enterMeterValue.placeholder',
            'popovers.enterMeterValue.text',
            'popovers.enterMeterValue.positive',
            'popovers.meterValueValidityCheck.title',
            'popovers.meterValueValidityCheck.text',
            'popovers.meterValueValidityCheck.positive',
            'popovers.meterValueValidityCheck.negative',
            'popovers.mfaCode.setupCode',
            'popovers.mfaCodeEntry.setupCode',
            'popovers.disableMFAConfirmation.title',
            'popovers.disableMFAConfirmation.text',
            'popovers.disableMFAConfirmation.positive',
            'popovers.disableMFAConfirmation.negative',
            'popovers.appliancesRetraining.title',
            'popovers.appliancesRetraining.text',
            'popovers.appliancesRetraining.positive',
            'popovers.appliancesRetraining.negative',
            'common.errors.error',
            'popovers.appliancesRetrainingError.text',
            'popovers.appliancesRetrainingError.positive',
            'popovers.opticalReaderOnboardingInfo.content',
            'popovers.opticalReaderOnboardingInfo.title'
        ];

        keys.forEach(key => {
            this.translate.get(key).subscribe(value => {
                this.translations[key] = value;
            });
        });
    }

    getRemoveTilePopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            data: {
                title: this.translations['popovers.removeTile.title'],
                text: this.translations['popovers.removeTile.text'],
                positive: this.translations['popovers.removeTile.positive'],
                negative: this.translations['popovers.removeTile.negative']
            },
            hasBackdrop: true
        };
    }

    getLogoutPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            data: {
                title: this.translations['common.logout'],
                text: this.translations['popovers.logout.text'],
                positive: this.translations['popovers.logout.positive'],
                negative: this.translations['popovers.logout.negative']
            },
            hasBackdrop: true
        };
    }


    getOnboardingNoContactPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            data: {
                title: this.translations['popovers.onboardingNoContact.title'],
                text: this.translations['popovers.onboardingNoContact.text'],
                positive: this.translations['popovers.onboardingNoContact.positive']
            },
            hasBackdrop: true
        };
    }

    getOnboardingWrongSerialPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            data: {
                title: this.translations['popovers.onboardingWrongSerial.title'],
                text: this.translations['popovers.onboardingWrongSerial.text'],
                positive: this.translations['popovers.onboardingWrongSerial.positive']
            },
            hasBackdrop: true
        };
    }


    getPinFailedPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                nullableBackdrop: true,
                title: this.translations['popovers.pinFailed.title'],
                text: this.translations['popovers.pinFailed.text'],
                positive: this.translations['popovers.pinFailed.positive'],
                negative: this.translations['popovers.pinFailed.negative'],
                turquoise: true,
                infoLink: this.translations['popovers.pinFailed.infoLink'] || 'Default InfoLink'
            }
        };
    }


    getManualPinEntryPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                nullableBackdrop: true,
                title: this.translations['popovers.manualPinEntry.title'],
                text: this.translations['popovers.manualPinEntry.text'],
                positive: this.translations['popovers.manualPinEntry.positive'],
                turquoise: true,
                infoLink: this.translations['popovers.manualPinEntry.infoLink'] || 'Default InfoLink'
            }
        };
    }


    getPinEntryPopoverConfig() {
        return {
            content: ValueEntryPopoverComponent,
            hasBackdrop: true,
            data: {
                nullableBackdrop: true,
                type: 'number',
                title: this.translations['popovers.pinEntry.title'],
                placeholder: this.translations['popovers.pinEntry.placeholder'] || 'Default Placeholder',
                text: this.translations['popovers.pinEntry.text'],
                positive: this.translations['popovers.pinEntry.positive'],
                turquoise: true,
                hasSkip: true,
                maxlength: 4,
            }
        };
    }


    getManualPinEntryInfoPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                nullableBackdrop: true,
                title: this.translations['popovers.manualPinEntryInfo.title'],
                text: this.translations['popovers.manualPinEntryInfo.text'],
                positive: this.translations['popovers.manualPinEntryInfo.positive'],
                turquoise: true,
                infoLink: this.translations['popovers.manualPinEntryInfo.infoLink'] || 'Default InfoLink'
            }
        };
    }


    getFirmwareUpdatePopover() {
        return {
            content: UpdatePopoverComponent,
            hasBackdrop: true,
            data: {}
        };
    }


    getFirmwareUpdateAvailablePopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                nullableBackdrop: true,
                title: this.translations['popovers.firmwareUpdateAvailable.title'],
                text: this.translations['popovers.firmwareUpdateAvailable.text'],
                positive: this.translations['popovers.firmwareUpdateAvailable.positive'],
                negative: this.translations['common.later']
            }
        };
    }


    getRadioLinkLostPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                title: this.translations['popovers.radioLinkLost.title'],
                text: this.translations['popovers.radioLinkLost.text'],
                positive: this.translations['common.info.okay']
            }
        };
    }


    getMFACodePopover() {
        return {
            content: MfaCodePopoverComponent,
            hasBackdrop: true,
            data: {
                setupCode: ''
            }
        };
    };


    getMFACodeEntryPopover() {
        return {
            content: MfaCodeEntryPopoverComponent,
            hasBackdrop: true,
            data: {
                setupCode: ''
            }
        };
    };


    getDisableMFAConfirmationPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                title: this.translations['popovers.disableMFAConfirmation.title'],
                text: this.translations['popovers.disableMFAConfirmation.text'],
                positive: this.translations['popovers.disableMFAConfirmation.positive'],
                negative: this.translations['popovers.disableMFAConfirmation.negative']
            }
        };
    }


    getMFALoginPopover() {
        return {
            content: MfaLoginPopoverComponent,
            hasBackdrop: true,
            data: {}
        };
    };


    getAppliancesRetrainingPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                title: this.translations['popovers.appliancesRetraining.title'],
                text: this.translations['popovers.appliancesRetraining.text'],
                positive: this.translations['popovers.appliancesRetraining.positive'],
                negative: this.translations['popovers.appliancesRetraining.negative']
            }
        };
    }


    getAppliancesRetrainingErrorPopoverConfig() {
        return {
            content: BinaryConfirmPopoverComponent,
            hasBackdrop: true,
            data: {
                title: this.translations['common.errors.error'],
                text: this.translations['popovers.appliancesRetrainingError.text'],
                positive: this.translations['popovers.appliancesRetrainingError.positive']
            }
        };
    }


    getOpticalReaderOnboardingInfoPopover() {
        return {
            content: InfoPopoverComponent,
            hasBackdrop: true,
            data: {
                title: this.translations['popovers.opticalReaderOnboardingInfo.title'],
                content: this.translations['popovers.opticalReaderOnboardingInfo.content'],
            }
        };
    }
}
