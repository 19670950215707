<aside class="sidebar lft">
    <div class="menu-container">
        <div class="menu-header">
            <div [ngClass]="{'title-image': !application.isDemoMode(), 'title-image-demo' : application.isDemoMode()}">
            </div>
        </div>
        <div class="menu-nav">
            <ul class="menu-entries">
                <li class="menu-item-wrapper">
                    <img class="menu-icon" src="assets/img/icons/dashboard-icon-iona.png" alt="Dashboard Icon"
                         height="22" width="22"/>
                    <a class="menu-item" routerLink="/" title="{{ 'screens.menu.overview' | translate }}"
                       [ngClass]="{active : activeElement == 'dashboard'}">
                        {{ 'screens.menu.overview' | translate }}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/meine-daten" title="{{ 'common.myData' | translate }}"
                       [ngClass]="{active : activeElement == 'profile'}">
                        {{ 'common.myData' | translate }}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/mein-haushalt"
                       title="{{ 'screens.menu.myHousehold' | translate }}"
                       [ngClass]="{active: activeElement === 'household'}">
                        {{ 'screens.menu.myHousehold' | translate }}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/einstellungen" title="{{ 'screens.menu.settings' | translate }}"
                       [ngClass]="{active: activeElement === 'settings'}">
                        {{ 'screens.menu.settings' | translate }}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/kontakt" title="{{ 'common.contact' | translate }}"
                       [ngClass]="{active: activeElement === 'contact'}">
                        {{ 'common.contact' | translate }}
                    </a>
                </li>
                <li>
                    <a class="menu-item" routerLink="/hilfe" title="{{ 'screens.menu.help' | translate }}"
                       [ngClass]="{active: activeElement === 'help'}">
                        {{ 'screens.menu.help' | translate }}
                    </a>
                </li>
                <li>
                    <a class="menu-item" href="javascript:;"
                       title="{{ application.isDemoMode() ? ('screens.menu.exitDemoMode' | translate) : ('common.logout' | translate) }}"
                       (click)="onLogoutClick()">
                        {{ application.isDemoMode() ? ('screens.menu.exitDemoMode' | translate) : ('common.logout' | translate) }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="pre-footer">
            <div>
                <a href="javascript:;" class="menu-link version"
                   (click)="showChangelog()">
                    {{ version }}
                </a>
            </div>
            <div class="provider-menu-logo-container">
                <div class="provider-menu-logo" [ngClass]="'iona-with-text'"></div>
            </div>
        </div>
        <div class="menu-footer">
            <a href="javascript:;" class="menu-link" routerLink="/impressum"
               title="{{ 'screens.menu.imprint' | translate }}"
               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_imprint">
                {{ 'screens.menu.imprint' | translate }}
            </a>
            <a (click)="onPrivacyClick()" class="menu-link" target="_blank"
               title="{{ 'screens.menu.privacyPolicy' | translate }}"
               angulartics2On="click" angularticsCategory="Menü" angularticsAction="view_privacy">
                {{ 'screens.menu.privacyPolicy' | translate }}
            </a>
        </div>
    </div>
</aside>
