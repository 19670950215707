import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {BasePopover} from '../../../classes/BasePopover';
import {PopoverRef} from '../../../popovers/popover/popover-ref';
import {HomestateService} from '../../../services/homestate.service';
import {map, mergeMap, switchMap} from 'rxjs/operators';
import {MetaResponse} from '../../../shared/interfaces/meta-response';
import {Observable, of} from 'rxjs';
import * as moment from 'moment/moment';
import {HttpErrorResponse} from '@angular/common/http';
import {
    RealTimeAlertChartComponent
} from '../../../charts/real-time-alert-chart/real-time-alert-chart.component';
import {ConfigurationService} from '../../../services/configuration.service';
import {ApplicationService} from '../../../services/application.service';
import {AnimationOptions} from 'ngx-lottie';
import {RealTimeAlertService} from '../../../services/real-time-alert.service';
import {TranslateService} from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';

enum AlarmViewMode {
    OVERVIEW = 'Overview',
    MANAGE = 'Alarme verwalten'
}

@Component({
    selector: 'app-real-time-alert-details',
    templateUrl: './real-time-alert-details.component.html',
    styleUrls: ['./real-time-alert-details.component.scss'],
    viewProviders: [ApiService]
})
export class RealTimeAlertDetailsComponent extends BasePopover implements OnInit, OnDestroy, AfterViewInit {
    showEmptyState = true;
    noAlarmState = true;
    infoVisible = false;
    readonly alarmViewMode = AlarmViewMode;
    currentMode = AlarmViewMode.OVERVIEW;
    private alertChart: RealTimeAlertChartComponent = null;
    private cachedDiagramData = null;
    feedinDiagramSeriesColor = '#39393a';
    chartInitialized = false;
    private lastFetchedDataset = null;
    alarmsFromConfig = null;
    alarmsData = null;
    currentDataOffset = 0;
    maxDataOffset = 6;
    powerValue = null;
    firstAbovePowerValue = null;
    firstBelowPowerValue = null;
    aboveIcon = null;
    belowIcon = null;
    dashboardConfiguration = null;
    meterAboveCenter = false;
    meterPowerValue = null;
    isSaving = false;
    dropDownOptions: {
        label: string;
        value: string;
        editable?: boolean;
        thresholdValue?: number
    }[] = [
        {
            label: this.translate.instant('screens.dashboard.realTimeAlarmDetail.highConsumption'),
            value: 'high',
            editable: false
        },
        {
            label: this.translate.instant('screens.dashboard.realTimeAlarmDetail.middleConsumption'),
            value: 'medium',
            editable: false
        },
        {
            label: this.translate.instant('screens.dashboard.realTimeAlarmDetail.lowConsumption'),
            value: 'low',
            editable: false
        },
        {
            label: this.translate.instant('screens.dashboard.realTimeAlarmDetail.customConsumption'),
            value: 'custom',
            editable: true
        },
        {
            label: this.translate.instant('screens.dashboard.realTimeAlarmDetail.feedInConsumption'),
            value: 'feed_in',
            editable: true
        }
    ];
    thresholdValues: number[] = [];
    originalAlarmsData = null;
    newAlarm: any = {
        enabled: true,
        threshold_value: null,
        type: 'custom',
        name: '',
        key: '',
        cooldown: 15,
        aggregation_method: 'mean',
        aggregation_sample_count: 5,
        aggregation_sample_interval: 1
    };


    constructor(protected popoverRef: PopoverRef,
                private homestate: HomestateService,
                private application: ApplicationService,
                private configuration: ConfigurationService,
                private realTimeAlertService: RealTimeAlertService,
                private translate: TranslateService,
                private snackBar: MatSnackBar) {
        super(popoverRef);
    }

    lottieConfig: AnimationOptions = {
        path: 'assets/anim/empty-states/consumption-alert.json',
        renderer: 'svg',
        autoplay: true,
        loop: true,
        name: 'Echtzeitalarm '
    };

    ngOnInit() {
        this.getAlarmsFromDashboardConfig().pipe(
            switchMap(() => this.getAlarmsData())
        ).subscribe({
            next: (mappedThresholds) => {
                this.alarmsData = mappedThresholds || [];
                this.originalAlarmsData = JSON.parse(JSON.stringify(mappedThresholds));
                if (this.chartInitialized) {
                    this.addHorizontalLines();
                }
                if (mappedThresholds.length) {
                    this.noAlarmState = false;
                }
            },
            error: (err) => {
                console.error('Error fetching alarms:', err);
            }
        });
        this.getHomeStateStatus();
    }

    onClose(): void {
        this.resetUnsavedAlarms();
        this.popoverRef.close();
        this.realTimeAlertService.notifyClose();
    }

    private resetUnsavedAlarms(): void {
        this.alarmsData = JSON.parse(JSON.stringify(this.originalAlarmsData));
    }

    private showErrorSnackbar(): void {
        const errorMessage = this.translate.instant('common.errors.apiErrorMessage', { appName: 'IONA' });

        this.snackBar.open(
            errorMessage,
            'OK',
            {
                duration: 50000,
                panelClass: ['snackbar-error'],
                horizontalPosition: 'center',
                verticalPosition: 'top'
            }
        );
    }

    ngAfterViewInit() {
        this.getSevenDayHistoricalData();
        this.getAlarmsData();
        if (this.alarmsData?.length) {
            this.noAlarmState = false;
        }
    }

    hasChanges(): boolean {
        return JSON.stringify(this.alarmsData) !== JSON.stringify(this.originalAlarmsData);
    }

    addAlarm(): void {
        if (this.alarmsData?.length === 4) {
            return;
        }

        const existingKeys = this.alarmsData?.map(alarm => alarm.key);
        let newKey = null;
        for (let i = 0; i < 4; i++) {
            const candidateKey = `threshold_${i}`;
            if (!existingKeys.includes(candidateKey)) {
                newKey = candidateKey;
                break;
            }
        }

        if (!newKey) {
            console.error('Failed to generate a valid key for the new alarm.');
            return;
        }

        this.alarmsData?.push({...this.newAlarm, key: newKey});
        if (this.alarmsData?.length) {
            this.noAlarmState = false;
        }
    }

    getIconForAlarm(type: string): string {
        return this.getIconForAlarmType(type);
    }

    updateAlarmType(alarm): void {
        if (alarm.type === 'low') {
            alarm.threshold_value = this.thresholdValues[0];
        } else if (alarm.type === 'medium') {
            alarm.threshold_value = this.thresholdValues[1];
        } else if (alarm.type === 'high') {
            alarm.threshold_value = this.thresholdValues[2];
        } else {
            alarm.threshold_value = null;
        }
    }

    saveAlarms(): void {
        this.isSaving = true;
        const payload = {
            mains: {
                ...this.alarmsData?.reduce((acc, alarm) => {
                    acc[alarm.key] = {
                        enabled: alarm.enabled,
                        threshold_value: alarm.threshold_value,
                        alert_type: alarm.type === 'feed_in' ? 'lower' : 'upper',
                        cooldown: alarm.cooldown,
                        aggregation_method: alarm.aggregation_method,
                        aggregation_sample_count: alarm.aggregation_sample_count,
                        aggregation_sample_interval: alarm.aggregation_sample_interval
                    };
                    return acc;
                }, {})
            }
        };

        this.homestate.updatePowerThresholds(payload).subscribe({
            next: () => {
                this.isSaving = false;
                this.updateDashboardConfiguration();
                this.originalAlarmsData = JSON.parse(JSON.stringify(this.alarmsData));
            },
            error: (err) => {
                this.isSaving = false;
                if (err?.error?.error?.code === 117) {
                    this.showErrorSnackbar();
                } else {
                    console.error('Error adding alarm:', err);
                }
            },
        });
    }

    private updateDashboardConfiguration() {
        if (this.dashboardConfiguration?.data?.power_thresholds) {
            const updatedPowerThresholds = this.alarmsData.reduce((acc, alarm) => {
                if (alarm.key) {
                    acc[alarm.key] = {
                        name: alarm.name,
                        type: alarm.type || 'custom',
                    };
                }
                return acc;
            }, {});

            this.dashboardConfiguration.data.power_thresholds = updatedPowerThresholds;

            const payload = {
                config: {
                    ...this.dashboardConfiguration.data,
                    power_thresholds: updatedPowerThresholds
                }
            };

            this.configuration.saveAlarmDataToConfig(payload).subscribe({
                next: (response) => {},
                error: (error) => {
                    console.error('Error updating dashboard configuration:', error);
                },
            });
        } else {
            console.error('Dashboard configuration or power thresholds not found.');
        }
    }

    get isSaveDisabled(): boolean {
        const hasInvalidAlarms = this.alarmsData?.some(alarm => {
            return !alarm.key || !alarm.name || !alarm.type || !alarm.threshold_value;
        });
        return hasInvalidAlarms || !this.hasChanges() || this.isSaving;
    }

    toggleAlarms(isEnabled: boolean, key: string): void {
        const alarmToUpdate = this.alarmsData?.find((alarm) => alarm.key === key);

        if (alarmToUpdate) {
            alarmToUpdate.enabled = !isEnabled;
            if (this.currentMode === AlarmViewMode.MANAGE) {
                return;
            }

            const updatedThreshold = {
                [alarmToUpdate.key]: {
                    aggregation_method: alarmToUpdate.aggregation_method,
                    aggregation_sample_count: alarmToUpdate.aggregation_sample_count,
                    aggregation_sample_interval: alarmToUpdate.aggregation_sample_interval,
                    enabled: alarmToUpdate.enabled,
                    cooldown: alarmToUpdate.cooldown,
                    threshold_value: alarmToUpdate.threshold_value,
                    alert_type: alarmToUpdate?.alert_type || 'upper',
                }
            };

            const payload = {
                mains: updatedThreshold
            };

            this.homestate.updatePowerThresholds(payload).subscribe({
                next: () => {
                    this.originalAlarmsData = this.alarmsData;
                    this.addHorizontalLines();
                    this.originalAlarmsData = JSON.parse(JSON.stringify(this.alarmsData));
                },
                error: (err) => {
                    if (err?.error?.error?.code === 117) {
                        this.showErrorSnackbar();
                    } else {
                        console.error('Error updating power thresholds:', err);
                    }
                    alarmToUpdate.enabled = isEnabled;
                    this.addHorizontalLines();
                }
            });
        } else {
            console.warn(`Alarm with name ${key} not found`);
        }
    }


    setMode(newMode: AlarmViewMode) {
        if (newMode === this.currentMode) {
            return;
        }

        if (this.hasChanges()) {
            this.resetUnsavedAlarms();
        }

        if (!this.alarmsData?.length) {
            this.noAlarmState = true;
        }

        this.currentMode = newMode;
    }


    onChartLoaded(chart: RealTimeAlertChartComponent): void {
        this.alertChart = chart;
        this.chartInitialized = true;
        this.updateDiagramFromCachedData();
        if (this.alarmsData?.length) {
            this.addHorizontalLines();
        }
    }

    addHorizontalLines() {
        this.alertChart.resetHorizontalLines();

        this.alarmsData?.forEach((data) => {
            if (data.enabled) {
                switch (data.type) {
                    case 'custom':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#39393a', '/assets/img/graphics/real-time/service.png');
                        break;
                    case 'feed_in':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#ffc300', '/assets/img/graphics/real-time/sun-inverted.png');
                        break;
                    case 'high':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#b9280a', '/assets/img/graphics/real-time/high-consumption.png');
                        break;
                    case'medium':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#eb4b0a', '/assets/img/graphics/real-time/mid-consumption.png');
                        break;
                    case 'low':
                        this.alertChart.addHorizontalLine(data.threshold_value, '#eb9674', '/assets/img/graphics/real-time/low-consumption.png');
                        break;
                    default:
                        this.alertChart.addHorizontalLine(data.threshold_value, '#39393a', '');
                }
            }
        });
    }

    determineMeterIcons(type: string): string {
        switch (type) {
            case 'custom':
                return '/assets/img/graphics/real-time/custom.png';
            case 'feed_in':
                return '/assets/img/graphics/real-time/feed_in.png';
            case 'high':
            case'medium':
            case 'low':
                return '/assets/img/graphics/real-time/power.png';
            default:
                return '';
        }
    }

    determineMeterValue(): string {
        if (!this.powerValue?.power || !this.alarmsData?.length) {
            return '/assets/img/graphics/real-time/no-alarms.png';
        }
        const power = this.powerValue?.power;

        let closestBelow: { value: number; type: string } = null;
        let closestAbove: { value: number; type: string } = null;

        for (const alarm of Object.values(this.alarmsData)) {
            // @ts-ignore
            const {threshold_value: val, type} = alarm;

            if (val <= power) {
                if (!closestBelow || power - val <= power - closestBelow.value) {
                    closestBelow = {value: val, type};
                }
            } else if (val >= power) {
                if (!closestAbove || val - power <= closestAbove.value - power) {
                    closestAbove = {value: val, type};
                }
            }
        }

        if (closestBelow) {
            this.firstBelowPowerValue = closestBelow.value;
            this.belowIcon = this.determineMeterIcons(closestBelow.type);
        } else {
            this.firstBelowPowerValue = null;
        }

        if (closestAbove) {
            this.firstAbovePowerValue = closestAbove.value;
            this.aboveIcon = this.determineMeterIcons(closestAbove.type);
        } else {
            this.firstAbovePowerValue = null;
        }

        const min = this.firstBelowPowerValue;
        const max = this.firstAbovePowerValue;
        let percentage = 0;

        if (max && !min) {
            percentage = Math.abs(power - min) * 100.0 / max;
            this.meterAboveCenter = true;
            this.meterPowerValue = Math.abs(max - power);
        } else if (!max && min) {
            percentage = Math.abs(power - min) * 100.0 / min;
            this.meterAboveCenter = false;
            this.meterPowerValue = Math.abs(min - power);
        } else {
            percentage = Math.abs(power - min) * 100.0 / (max - min);
            this.meterAboveCenter = percentage >= 50;
            this.meterPowerValue = percentage >= 50 && max ? max - power : power - min;
        }

        if (percentage < 12.5) {
            return '/assets/images/meter_zero.png';
        } else if (percentage >= 12.5 && percentage < 37.5) {
            return '/assets/images/meter_quarter_left.png';
        } else if (percentage >= 37.5 && percentage < 62.5) {
            return '/assets/images/meter_middle.png';
        } else if (percentage >= 62.5 && percentage < 87.5) {
            return '/assets/images/meter_quarter_right.png';
        } else if (percentage >= 87.5) {
            return '/assets/images/meter_full.png';
        }

        return '/assets/images/meter_zero.png';
    }

    private getAlarmsFromDashboardConfig(): Observable<void> {
        if (this.application.isDemoMode()) {
            return of();
        }

        return this.configuration.requestAlarmsDataFromConfig().pipe(
            map((storedData) => {
                if (storedData.status === 'ok') {
                    this.dashboardConfiguration = storedData;
                    this.alarmsFromConfig = storedData?.data?.power_thresholds;
                }
            })
        );
    }

    private getAlarmsData(): Observable<any[]> {
        return this.homestate.fetchPowerThresholds().pipe(
            map(({data}) => {
                return Object.entries(data?.mains).map(([key, threshold]) => {
                    const configData = this.alarmsFromConfig[key];
                    return {
                        // @ts-ignore
                        ...threshold,
                        name: configData?.name || 'Unknown',
                        type: configData?.type || 'unknown',
                        key,
                    };
                });
            })
        );
    }

    getIconForAlarmType(alarmType: string, isTitle: boolean = false): string {
        switch (alarmType) {
            case 'custom':
                return isTitle ? '/assets/img/graphics/real-time/custom-bell.png' :
                    '/assets/img/graphics/real-time/service-alarm.png';
            case 'feed_in':
                return isTitle ? '/assets/img/graphics/real-time/feed-in-bell.png' :
                    '/assets/img/graphics/real-time/sun-gray.png';
            case 'high':
                return isTitle ? '/assets/img/graphics/real-time/high-bell.png' :
                    '/assets/img/graphics/real-time/high-alarm.png';
            case'medium':
                return isTitle ? '/assets/img/graphics/real-time/mid-bell.png' :
                    '/assets/img/graphics/real-time/mid-alarm.png';
            case 'low':
                return isTitle ? '/assets/img/graphics/real-time/low-bell.png' :
                    '/assets/img/graphics/real-time/low-alarm.png';
            default:
                return isTitle ? '/assets/img/graphics/real-time/feed-in-bell.png' :
                    '/assets/img/graphics/real-time/service-alarm.png';
        }
    }


    stepForward(): void {
        --this.currentDataOffset;
        this.updateDiagramFromCachedData();
    }


    stepBack(): void {
        ++this.currentDataOffset;
        this.updateDiagramFromCachedData();
    }

    resetHistory(): void {
        this.currentDataOffset = 0;
        this.updateDiagramFromCachedData();
    }


    private getSevenDayHistoricalData(): void {
        this.realTimeAlertService.onRealTimeAlertDataUpdate.pipe(
            mergeMap((results: MetaResponse) => {
                if (results) {
                    if (results.status === 'error') {
                        this.handleInstantaneousErrors(results.data);
                    } else {
                        return of(results.data.results);
                    }
                }
                return of(null);
            })).subscribe({
            next: result => {
                if (!result) {
                    return;
                }
                this.cachedDiagramData = result;
                this.powerValue = result[result.length - 1];
                this.updateDiagramFromCachedData();
                this.showEmptyState = false;
            },
            error: error => {
                if (this.chartInitialized) {
                    this.alertChart.showLoadingState();
                    console.log('Error: ', error);
                }
            },
        });
        this.realTimeAlertService.startRealTimeAlertValueLiveUpdate(this.maxDataOffset);
    }

    private extractSeriesDataFromCache(): void {
        const start = moment().subtract(this.currentDataOffset + 1, 'days').toDate();
        const end = moment().subtract(this.currentDataOffset, 'days').toDate();
        this.lastFetchedDataset = this.cachedDiagramData.filter(el => {
            const ts = moment(el.timestamp).toDate();
            return ts >= start && ts <= end;
        });
    }


    private updateDiagramFromCachedData(): void {
        try {
            this.extractSeriesDataFromCache();

            const datasetMaxValue = Math.max(...this.cachedDiagramData?.map(data => data.power));
            const highestAlarmThreshold = Math.max(
                datasetMaxValue,
                ...this.alarmsData
                    ?.filter(alarm => alarm.enabled && alarm.threshold_value)
                    ?.map(alarm => alarm.threshold_value)
            );

            if (this.alarmsData?.length && this.chartInitialized) {
                const adjustedMaxValue = highestAlarmThreshold * 1.1;
                this.updateYAxisRange(adjustedMaxValue);
            }

            if (this.chartInitialized) {
                this.updateChart();
            }

        } catch (e) {
            console.error('Error while updating the diagram:', e);

            if (this.chartInitialized) {
                this.alertChart.showLoadingState();
            }
        }
    }

    updateYAxisRange(max: number): void {
        if (!this.alertChart) {
            return;
        }

        const yAxis = this.alertChart.chart.ref.yAxis[0];

        if (yAxis) {
            yAxis.update({
                max
            });
        }
    }

    private updateChart(): void {
        this.alertChart.showLoadingState(false);
        const fedEnergy = [];
        for (const el of this.lastFetchedDataset) {
            const element = {
                power: el.power < 0 ? Math.abs(el.power) : null,
                timestamp: el.timestamp
            };
            if (element.power) {
                fedEnergy.push(element);
            }
        }
        const consumedEnergy = [];
        for (const el of this.lastFetchedDataset) {
            const element = {
                power: el.power >= 0 ? el.power : null,
                timestamp: el.timestamp
            };
            if (element.power) {
                consumedEnergy.push(element);
            }
        }
        if (consumedEnergy.length) {
            this.alertChart.addNewSeries(
                consumedEnergy,
                'power',
                1,
            );
        }

        if (fedEnergy.length) {
            this.alertChart.addNewSeries(
                fedEnergy,
                'power',
                0,
                {zIndex: 2, isTileChart: true, color: this.feedinDiagramSeriesColor}
            );
        }

        const time = moment(this.lastFetchedDataset.first().timestamp).endOf('day');
        this.alertChart.insertVerticalLine(time.toDate());
    }


    private handleInstantaneousErrors(error: HttpErrorResponse): void {
        if (error.error.error.code === 290) {
            this.showEmptyState = true;
        }
        this.chartInitialized = false;
    }

    isEditable(type: string): boolean {
        const option = this.dropDownOptions.find((opt) => opt.value === type);
        return option?.editable ?? true;
    }

    getHomeStateStatus(): void {
        this.homestate.requestHomestateConfig().subscribe(
            (result) => {
                if (!result) {
                    return;
                }
                this.thresholdValues = result.thresholds.sort((a, b) => a - b);
            }
        );
    }
}
